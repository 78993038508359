import React from "react";

import ImageWithSkeleton from "../../components/general/ImageWithSkeleton";
import ImageCensored from "../../components/general/ImageCensored";

const ImageDisplayHandler = ({
  src,
  alt,
  width = 512,
  height = 512,
  section_id = false,
  nsfw = false,
  isAuthenticated = false,
}) => {
  // If NSFW content and user is not authenticated, display the censored image
  if (nsfw && !isAuthenticated) {
    return (
      <div className="element-margin">
        <div className="container inline-flex">
          <ImageCensored
            src={src}
            alt={alt}
            height={height}
            width={width}
            section_id={section_id}
          />
        </div>
      </div>
    );
  }

  // If content is not NSFW or user is authenticated, display the regular image
  return (
    <div className="element-margin">
      <div className="container inline-flex">
        <ImageWithSkeleton
          src={src}
          alt={alt}
          height={height}
          width={width}
          section_id={section_id}
        />
      </div>
    </div>
  );
};

export default ImageDisplayHandler;
