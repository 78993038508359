import React from "react";
import ImageGallery from "./ImageGallery";
import SupportedByPatreon from "./SupportedByPatreon";
import theohartley from "../emblems/theo-hartley.svg";

const GalleryOverlay = ({
  imageUrl,
  height,
  titleText,
  subtitleText,
  images,
  altText,
}) => {
  const divStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1) 100%), url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: height,
    width: "auto",
    borderRadius: "10px",
    padding: "10px",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };

  return (
    <div style={divStyle} role="img" aria-label={altText}>
      <div style={{ height: "525px" }} />
      <div className="title" style={{ textAlign: "center" }}>
        {titleText}
      </div>
      <div style={{ height: "20px" }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="undertitle"
          style={{ textAlign: "center", width: "80%" }}
        >
          {subtitleText}
        </div>
      </div>
      <div style={{ height: "20px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <SupportedByPatreon />
        <img
          src={theohartley}
          alt="Theo Hartley signature"
          className="element-margin"
          width="220px"
          style={{ paddingTop: "14px", marginRight: "-12px" }}
        />
      </div>
      <ImageGallery images={images} />
    </div>
  );
};

export default GalleryOverlay;
