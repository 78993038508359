import React from "react";
import { Link } from "react-router-dom";
import styles from "./NavList.module.css";

const NavList = ({ story, chapter }) => {
  const chapterNum = chapter.chapter_num;
  const numChapters = story.num_chapters;
  const firstTo = `/story/${story.id}/chapter/1`;
  const secondTo =
    chapterNum === 1 ? "" : `/story/${story.id}/chapter/${chapterNum - 1}`;
  const thirdTo =
    chapterNum === story.num_chapters
      ? ""
      : `/story/${story.id}/chapter/${chapterNum + 1}`;
  const fourthTo = `/story/${story.id}/chapter/${numChapters}`;

  return (
    <div className={styles.arrowList}>
      <Link to={firstTo} className="circleButton circleThin">
        {"<-<-"}
      </Link>
      <Link rel="prev" to={secondTo} className="circleButton">
        {"<-"}
      </Link>
      <Link rel="next" to={thirdTo} className="circleButton">
        {"->"}
      </Link>
      <Link to={fourthTo} className="circleButton circleThin">
        {"->->"}
      </Link>
    </div>
  );
};

export default NavList;
