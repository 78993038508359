import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { useStories } from "../../StoryContext";
import { useUser } from "../../UserContext";

import BasePage from "../../components/general/BasePage";

import TopNav from "./TopNav";
import SectionStack from "./SectionStack";
import ChapterEnd from "./chapterend/ChapterEnd";
import SignUpCTA from "./chapterend/SignUpCTA";

import PaywallSelector from "../paywall/PaywallSelector";

export const Chapter = () => {
  const { storyId, chapterNum } = useParams();
  const { session, entitlement } = useUser();
  const { stories } = useStories();
  const [story, setStory] = useState();
  const [chapter, setChapter] = useState();
  const [sections, setSections] = useState([]);

  const isAuthenticated = session !== null;

  useEffect(() => {
    const getData = async () => {
      const storyData = stories.find((story) => story.id === storyId);
      setStory(storyData);

      if (storyData) {
        const chapterData = storyData.chapter.find(
          (chap) => chap.chapter_num === Number(chapterNum)
        );
        setChapter(chapterData);

        if (chapterData && chapterData.id) {
          try {
            const { data, error } = await supabase
              .from("section")
              .select(
                `id, content, type, img_width, img_height, alt_text, nsfw`
              )
              .eq("chapter_id", chapterData.id)
              .order("order_num", { ascending: true });

            if (error) {
              console.error("Error fetching data:", error);
            } else {
              setSections(data || []);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      }
    };

    if (stories.length > 0) {
      getData();
    }
  }, [chapterNum, stories, storyId]);

  return (
    <BasePage>
      {sections && chapter && story ? (
        <>
          <Helmet>
            <title>{`${story.title}: Chapter ${chapter.chapter_num} | Otherworlderotic`}</title>

            <meta
              name="description"
              content={`${story.title}: Chapter ${chapter.chapter_num} - ${chapter.title} | Otherworlderotic`}
            />

            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="author" content="Theo Hartley" />
            <meta name="robots" content="index,follow" />

            <link
              rel="canonical"
              href={`https://www.otherworlderotic.com/story/${storyId}/chapter/${chapterNum}`}
            />

            {/* Dynamic OpenGraph tags */}
            <meta property="og:site_name" content="Otherworlderotic" />
            <meta
              property="og:title"
              content={`${story.title}: Chapter ${chapter.chapter_num} | Otherworlderotic`}
            />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={chapter.thumbnail_image_url} />
            <meta
              property="og:description"
              content={`${story.title}: Chapter ${chapter.chapter_num} - ${chapter.title} | Otherworlderotic`}
            />
            <meta
              property="og:url"
              content={`https://www.otherworlderotic.com/story/${storyId}/chapter/${chapterNum}`}
            />

            {/* Dynamic Schema.org tags for Google */}
            <meta
              itemProp="name"
              content={`${story.title}: Chapter ${chapter.chapter_num} | Otherworlderotic`}
            />
            <meta
              itemProp="description"
              content={`${story.title}: Chapter ${chapter.chapter_num} - ${chapter.title} | Otherworlderotic`}
            />
            <meta itemProp="image" content={chapter.thumbnail_image_url} />
          </Helmet>
          <TopNav chapter={chapter} story={story} />
          <SectionStack sections={sections} isAuthenticated={isAuthenticated} />
          {chapter.visibility === "patron" &&
          entitlement !== "ACTIVE5" &&
          entitlement !== "ACTIVE10" &&
          entitlement !== "GRANT" ? (
            <PaywallSelector story={story.id} />
          ) : (
            <ChapterEnd chapter={chapter} story={story} />
          )}
        </>
      ) : (
        <div style={{ height: "1000px" }} />
      )}

      {!isAuthenticated && <SignUpCTA />}
    </BasePage>
  );
};
export default Chapter;
