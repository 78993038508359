import React, { useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

import NavBarButtons from "./navBarButtons";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="header-footer-container">
      <Link to="/" className="wordmark">
        <p>otherworlderotic</p>
      </Link>
      <div className="sm-show bm-burger-button">
        <Menu
          right
          width={"60%"}
          isOpen={menuOpen}
          noOverlay
          onStateChange={handleStateChange}
        >
          <div style={{ height: "13px" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className="body" style={{ fontWeight: 500, color: "#bdc4cf" }}>
              Stories
            </p>
            <Link
              to="/story/laura-the-bold/the-story"
              onClick={closeMenu}
              style={{
                color: "white",
                fontFamily: "sans-serif",
                marginLeft: "15px",
              }}
            >
              Laura the Bold
            </Link>
            <br />
            <Link
              to="/story/josephine-on-fire/the-story"
              onClick={closeMenu}
              style={{
                marginLeft: "15px",
                color: "white",
                fontFamily: "sans-serif",
              }}
            >
              Josephine on Fire
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p className="body" style={{ fontWeight: 500, color: "#bdc4cf" }}>
              otherworlderotic
            </p>
            <Link
              to="/about"
              onClick={closeMenu}
              style={{
                color: "white",
                fontFamily: "sans-serif",
                marginLeft: "15px",
              }}
            >
              About
            </Link>
            <br />
            <a
              href="https://www.patreon.com/otherworlderotic"
              target="_blank"
              rel="noopener noreferrer"
              onClick={closeMenu}
              style={{
                color: "white",
                fontFamily: "sans-serif",
                marginLeft: "15px",
                textDecoration: "underline",
              }}
            >
              Patreon
            </a>
          </div>
          <div style={{ height: "75px" }} />
          <div style={{ fontSize: "26px" }}>
            <NavBarButtons showLogout={false} showDash={true} column={true} />
          </div>
        </Menu>
      </div>
      <div className="flex-row-center-spaced sm-vanish">
        <div className="flex-row-center-spaced mid-media-column">
          <div className="element-margin" />
          <a
            href="https://www.patreon.com/otherworlderotic"
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Patreon
          </a>
          <Link to="/about" className="nav-link">
            About
          </Link>
          <div className="flex-row-center-spaced">
            <NavBarButtons showDash={true} showLogout={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
