import React from "react";

import mixpanel from "../../mixpanel";

import TextBlock from "../../components/landing/TextBlock";
import ImageFadeText from "../../components/landing/ImageFadeText";
import CTAImageOverlay from "../../components/landing/CTAImageOverlay";
import CTABadge from "../../components/landing/CTABadge";
import CTABadgeBack from "../../components/landing/CTABadgeBack";
import Spacer from "../../components/landing/Spacer";
import OverlayCard from "../../components/landing/OverlayCard";

export const LauraPaywall = () => {
  const handleCTAClick = () => {
    mixpanel.track("Patreon Click", {
      "test iteration": "JO-PAT-INITIAL",
      "test condition": "JO-PAT-INITIAL",
    });
  };

  const rootCTAURL = "/patreon";

  const featuresBlock = [
    {
      textAlignment: "left",
      text: "Patrons unlock ALL stories!",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "See all 500+ images and 30 chapters of Laura the Bold now!",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "I am completely funded by my generous Patrons. They keep this site 100% ad free and online!",
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "Patrons power a new chapter every week!",
      isBold: true,
      textStyle: "subtitle",
    },
  ];

  const FAQ = [
    {
      textAlignment: "left",
      text: "Patron FAQ",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "How does my patreon account link to otherworlderotic.com?",
      isBold: true,
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• Linking is automatic and instant! You can read all the otherworlderotic stories immediately after joining.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "How often do you release new content?",
      isBold: true,
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• I release a new chapter every week, plus a monthly bonus pack to Enthusiast subscribers. I've never missed a week since starting otherworlderotic!",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "How much more do you plan on writing?",
      isBold: true,
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• I have SO many stories I want to tell! As long as I have supporters, I'll be writing!",
      textStyle: "undertitle",
    },
  ];

  const testimonial = `"I joined on Patreon just to get caught up on the stories, but you release SO MUCH that I decided to stay a patron and support you."
  -Jm99 on Patreon`;

  return (
    <div className="page mid-width">
      <OverlayCard
        imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Censored.webp"
        height="750px"
        titleText="This complete story is for Patrons!"
        subtitleText="Join on patreon and instantly unlock everything."
        altText="The cover for the erotic story Laura the Bold, by Theo Hartley"
        linkTo={rootCTAURL}
        onClick={handleCTAClick}
        CTAtext="Join on Patreon"
      />
      <TextBlock container={false} texts={featuresBlock} signature={true} />
      <CTAImageOverlay
        imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Laura%20cutie.webp"
        height="650px"
        CTAText="Read the next chapters"
        CTAColor="#860000"
        CTAFontColor="white"
        altText="Laura in cozy clothes, looking at the camera romantically."
        linkTo={rootCTAURL}
        onClick={handleCTAClick}
      />
      <TextBlock container={true} texts={FAQ} signature={true} />
      <Spacer />
      <CTABadge
        text="Unlock Everything"
        badgeColor="#4A39B6"
        textColor="white"
        paddingRight="10px"
        linkTo={rootCTAURL}
        onClick={handleCTAClick}
      />
      <Spacer />
      <ImageFadeText
        imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20College%20Party.webp"
        height="600px"
        text={testimonial}
        gradient=".6"
      />
      <Spacer />
      <CTABadge
        text="Support otherworlderotic!"
        badgeColor="#4A39B6"
        textColor="white"
        paddingRight="10px"
        linkTo={rootCTAURL}
        onClick={handleCTAClick}
      />
      <Spacer />
      <CTAImageOverlay
        imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20in%20Bra.webp"
        height="650px"
        CTAText="Join on Patreon"
        CTAColor="#4A39B6"
        CTAFontColor="white"
        altText="Laura in a bar, suggestive."
        linkTo={rootCTAURL}
        onClick={handleCTAClick}
      />
      <TextBlock
        texts={[
          {
            text: "If now's not the time,",
            textAlignment: "center",
            textStyle: "caption",
          },
        ]}
      />
      <CTABadgeBack
        text="back to a free chapter"
        badgeColor="#bdc4cf"
        textColor="black"
        paddingRight="10px"
        linkTo={"/story/josephine-on-fire/chapter/1"}
        onClick={handleCTAClick}
      />
      <Spacer />
      <CTAImageOverlay
        imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Cute%20Selfie.webp"
        height="650px"
        CTAText="Join on Patreon"
        CTAColor="#4A39B6"
        CTAFontColor="white"
        altText="Laura in a cute sweater, taking a selfie."
        linkTo={rootCTAURL}
        onClick={handleCTAClick}
      />
      <TextBlock
        texts={[
          {
            text: "Support with a one-time credit card payment.",
            textAlignment: "center",
            textStyle: "caption",
          },
        ]}
      />
      <CTABadge
        text="Buy Laura the Bold"
        badgeColor="#4A39B6"
        textColor="white"
        paddingRight="10px"
        linkTo={"/cc-payment"}
        onClick={handleCTAClick}
      />
      <Spacer />
    </div>
  );
};

export default LauraPaywall;
