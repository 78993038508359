import React, { useState } from "react";
import { useUser } from "../../UserContext";
import { supabase } from "../../supabaseClient";

const ImageWithSkeleton = ({
  src,
  alt,
  width = 512,
  height = 512,
  disableFavorites = false,
  section_id = false,
  favoriteState = false,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [isFavorite, setIsFavorite] = useState(favoriteState);
  const [showIcon, setShowIcon] = useState(false);
  const [longPressActive, setLongPressActive] = useState(false);
  const { session } = useUser();
  const isAuthenticated = session !== null;

  const handleTouchStart = () => {
    setLongPressActive(true);
    setTimeout(() => {
      if (longPressActive) {
        if (showIcon) {
          setShowIcon(false);
        } else {
          setShowIcon(true);
        }
      }
    }, 500);
  };

  const handleTouchEnd = () => {
    setLongPressActive(false);
  };

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const handleIconClick = () => {
    if (isFavorite) {
      removeFromFavorites();
      setIsFavorite(false);
    } else {
      saveToFavorites();
      setIsFavorite(true);
    }
  };

  const saveToFavorites = async () => {
    const { error } = await supabase.from("user_favorites").upsert({
      section_id: section_id,
    });
    if (error) {
      console.error("Error adding favorite");
    } else {
      console.log("Favorite added successfully!");
    }
  };

  const removeFromFavorites = async () => {
    const { error } = await supabase
      .from("user_favorites")
      .delete()
      .eq("section_id", section_id);

    if (error) {
      console.error("Error removing favorite");
    } else {
      console.log("Favorite removed successfully!");
    }
  };

  const Skeleton = (
    <div
      className="skeleton"
      style={{ width: `${width}px`, height: `${height}px` }}
    ></div>
  );

  return (
    <div
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={{ position: "relative" }}
    >
      {!loaded && Skeleton}
      {isAuthenticated && !disableFavorites && showIcon && (
        <div
          onClick={handleIconClick}
          style={{
            position: "absolute",
            top: "25px",
            right: "28px",
            cursor: "pointer",
            fontSize: "24px",
          }}
        >
          {isFavorite ? "💜" : "🤍"}
        </div>
      )}
      <img className="image" src={src} alt={alt} onLoad={handleImageLoad} />
    </div>
  );
};

export default ImageWithSkeleton;
