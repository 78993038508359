/* eslint-disable */
import React from "react";
import ReactMarkdown from "react-markdown";

const TextContainer = ({ markdown }) => {
  const textStyle = {
    textAlign: markdown.length < 10 ? "center" : "left",
  };

  return (
    <div className="section-body">
      <div className="container">
        <div style={textStyle}>
          <ReactMarkdown children={markdown} />
        </div>
      </div>
    </div>
  );
};
export default TextContainer;
