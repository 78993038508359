import React from "react";
import CTABadge from "./CTABadge";

const CTAImageOverlay = ({
  imageUrl,
  height,
  CTAText,
  CTAColor,
  CTAFontColor,
  altText,
  linkTo = "/",
  onClick,
}) => {
  const divStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: height,
    width: "100%",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };

  const innerDivStyle = {
    position: "absolute",
    bottom: "30px",
    right: "20px",
  };

  return (
    <div style={divStyle} role="img" aria-label={altText}>
      <div style={innerDivStyle}>
        <CTABadge
          text={CTAText}
          badgeColor={CTAColor}
          textColor={CTAFontColor}
          paddingRight="10px"
          linkTo={linkTo}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default CTAImageOverlay;
