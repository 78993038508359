import React from "react";
import PropTypes from "prop-types";

import SupportedByPatreon from "./SupportedByPatreon";
import theohartley from "../emblems/theo-hartley.svg";

const TextBlock = ({ container, texts, signature = false }) => {
  const containerClass = container ? "container" : "container no-background";

  const renderTexts = (textItems) => {
    return textItems.map((textItem, index) => {
      const textStyle = {
        textAlign: textItem.textAlignment,
        marginBottom: "12px",
        fontWeight: textItem.isBold ? "bold" : "normal",
        fontStyle: textItem.isItalic ? "italic" : "normal",
      };

      const TextElement = textItem.isHeader ? "h1" : "div";

      return (
        <TextElement
          key={index}
          className={textItem.textStyle}
          style={textStyle}
        >
          {textItem.text}
        </TextElement>
      );
    });
  };

  return (
    <div
      className={containerClass}
      style={{ width: "95%", overflow: "hidden" }}
    >
      {renderTexts(texts)}

      {signature && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <SupportedByPatreon />
          <img
            src={theohartley}
            alt="Supported by Patreon"
            className="element-margin"
            width="220px"
            style={{ paddingTop: "14px", marginRight: "-8px" }}
          />
        </div>
      )}
    </div>
  );
};

TextBlock.propTypes = {
  container: PropTypes.bool,
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      textAlignment: PropTypes.oneOf(["center", "left", "right"]).isRequired,
      text: PropTypes.string.isRequired,
      textStyle: PropTypes.oneOf([
        "caption",
        "list",
        "title",
        "subtitle",
        "body",
        "quote",
        "undertitle",
      ]).isRequired,
      isBold: PropTypes.bool,
      isItalic: PropTypes.bool,
      isHeader: PropTypes.bool, // Optional boolean for wrapping text in <h1>
    })
  ).isRequired,
};

TextBlock.defaultProps = {
  container: false,
};

export default TextBlock;
