import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";

import BasePage from "../../components/general/BasePage";
import DashboardNavigator from "./DashboardNavigator";
import FavoritesGallery from "./FavoritesGallery";

export const DashboardFavorites = () => {
  const navigate = useNavigate();
  const { session } = useUser();
  const isAuthenticated = session !== null;
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const iconselection = "favorites";
  const title = "Favorite Images";
  const subtitle =
    "Press the heart icon at the top right of any image to see it here.";

  return (
    <BasePage>
      <DashboardNavigator
        iconselection={iconselection}
        title={title}
        subtitle={subtitle}
      />
      <FavoritesGallery />
    </BasePage>
  );
};

export default DashboardFavorites;
