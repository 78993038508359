import React, { useState } from "react";
import { supabase } from "../../supabaseClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import BasePage from "../../components/general/BasePage";

const ForgotPasswordPage = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);

      const { error } = await supabase.auth.resetPasswordForEmail(
        values.email,
        {
          redirectTo: "http://www.otherworlderotic.com/resetpassword",
        }
      );

      if (error) {
        setMessage(error.message);
      } else {
        setMessage("Password reset link has been sent to your email!");
      }

      setIsLoading(false);
      setSubmitting(false);
    },
  });

  return (
    <BasePage>
      <div className="container element-margin">
        <div className="inline-flex">
          <img
            src="/heart.png"
            style={{ height: "100px", width: "100px" }}
            alt="OWE Logo"
          />

          <p className="primary-font subtitle no-margin">Reset your password</p>
          <form className="form-area" onSubmit={formik.handleSubmit}>
            <div>
              <input
                className="input-field"
                type="email"
                name="email"
                placeholder="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                tabIndex={1}
                autoComplete="email"
              />
            </div>
            {message && <div className="form-message">{message}</div>}
            <button
              className="button mid"
              type="submit"
              disabled={isLoading || formik.isSubmitting}
              tabIndex={2}
            >
              {isLoading ? "..." : "Send"}
            </button>
          </form>
          <div className="element-margin" />
        </div>
      </div>
    </BasePage>
  );
};

export default ForgotPasswordPage;
