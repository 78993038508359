import React from "react";
import { Link } from "react-router-dom";

const CTABadgeBack = ({
  text,
  badgeColor,
  textColor,
  linkTo = "/",
  onClick,
}) => {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  const divStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "25px",
    backgroundColor: badgeColor,
    borderRadius: "50px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingRight: "20px",
    paddingLeft: "20px",
  };

  const textStyle = {
    fontSize: "18px",
    color: textColor,
    fontWeight: "600",
    letterSpacing: "0.5px",
  };

  const linkStyle = {
    textDecoration: "none",
  };

  return (
    <Link style={linkStyle} to={linkTo} onClick={handleClick}>
      <div style={divStyle}>
        <span style={textStyle}>{text}</span>
      </div>
    </Link>
  );
};

export default CTABadgeBack;
