import React from "react";
import { useLocation } from "react-router-dom";
import mixpanel from "../../mixpanel";
import signature from "../../components/emblems/theo-hartley.svg";

const PatreonCTA = () => {
  const location = useLocation();
  const page = location.pathname + location.search;

  const handleButtonClick = () => {
    mixpanel.track("Patreon Dashboard Click", {
      "Page URL": page,
    });
  };

  return (
    <div className="DashboardCTABackground">
      <div className="mid-width">
        <div className="element-margin-padding">
          <span className="mid-title secondary-font primary-color element-margin">
            Consider supporting me on Patreon!
          </span>
          <div />
          <span className="subtitle secondary-font accent-color element-margin">
            You&#39;ll get to read <b>all</b> the stories, plus, it helps me go
            full time!
          </span>
          <div />
          <div style={{ height: "25px" }} />
          <span className="title secondary-font primary-color element-margin">
            Thank you!
          </span>
          <div style={{ height: "10px" }} />
          <img src={signature} alt="Theo Hartley" />
          <div style={{ height: "35px" }} />
          <a
            className="button"
            href={"https://www.patreon.com/otherworlderotic"}
            onClick={handleButtonClick}
          >
            Unlock Everything
          </a>
        </div>
      </div>
    </div>
  );
};

export default PatreonCTA;
