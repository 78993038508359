import React from "react";

const HeartIcon = ({ color = "white" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 0 51 43"
    fill="none"
    width="75%"
    height="75%"
  >
    <path
      d="M26.9053 42.4694C26.1347 42.7414 24.8653 42.7414 24.0947 42.4694C17.5213 40.2254 2.83334 30.864 2.83334 14.9973C2.83334 7.99332 8.47734 2.32666 15.436 2.32666C19.5613 2.32666 23.2107 4.32133 25.5 7.40399C27.7893 4.32133 31.4613 2.32666 35.564 2.32666C42.5227 2.32666 48.1667 7.99332 48.1667 14.9973C48.1667 30.864 33.4787 40.2254 26.9053 42.4694Z"
      stroke={color}
      strokeWidth="4.311"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HeartIcon;
