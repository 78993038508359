import React from "react";

import ImageDisplayHandler from "./ImageDisplayHandler";
import TextContainer from "../../components/general/TextContainer";
import TextBlock from "../../components/landing/TextBlock";

const SectionStack = ({ sections, isAuthenticated }) => {
  return (
    <div className="section column no-margin">
      {sections.map((section) => {
        if (section.type === "image") {
          return (
            <ImageDisplayHandler
              key={section.id}
              src={section.content}
              alt={`Section ${section.id}: ${section.alt_text || "An image."}`}
              height={section.height || 768}
              width={section.width || 512}
              section_id={section.id}
              nsfw={section.nsfw}
              isAuthenticated={isAuthenticated}
            />
          );
        } else if (section.type === "text") {
          return (
            <div className="element-margin" key={section.id}>
              <TextContainer markdown={section.content} />
            </div>
          );
        }
        return null;
      })}
      <div className="mid-width element-margin">
        <TextBlock
          container={true}
          texts={[
            {
              textAlignment: "left",
              text: "Consider supporting these stories by donating on Patreon!",
              textStyle: "subtitle",
            },
          ]}
          signature={true}
        />
      </div>
    </div>
  );
};

export default SectionStack;
