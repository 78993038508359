import React from "react";
import { supabase } from "../../../supabaseClient";
import { Link, useLocation } from "react-router-dom";
import mixpanel from "../../../mixpanel";
import { useUser } from "../../../UserContext";

const NavBarButtons = ({ showDash, showLogout, column = false }) => {
  const { session } = useUser();
  const isAuthenticated = session !== null;
  const location = useLocation();
  const page = location.pathname + location.search;

  const handleSignUpClick = () => {
    mixpanel.track("Sign Up NavBar Click", {
      "Page URL": page,
    });
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
  };

  const containerClassNames = `flex-row-center-spaced ${
    column ? "column" : ""
  }`;

  return (
    <div className={containerClassNames}>
      {!isAuthenticated && (
        <div className={containerClassNames}>
          <Link
            to={"/signup"}
            className="buttonSmall fill"
            onClick={handleSignUpClick}
          >
            {"Sign Up"}
          </Link>
          <div style={{ height: "5px" }} />
          <Link className="buttonSmall" to="/login" state={{ from: location }}>
            Log In
          </Link>
        </div>
      )}
      {isAuthenticated && (
        <>
          {showDash && (
            <Link to={"/dashboard"} className="buttonSmall">
              {"Dashboard"}
            </Link>
          )}
          {showLogout && (
            <button className="buttonSmall fill" onClick={handleLogout}>
              Log Out
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default NavBarButtons;
