import React from "react";

const ChapterIcon = ({
  width = "53",
  height = "63",
  viewBox = "0 0 53 63",
  color = "white",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
  >
    <path
      d="M2.14865 50.9764C0.974054 50.9764 0 50.0024 0 48.8278V17.3143C0 4.68023 3.83892 0.841309 16.473 0.841309H36.527C49.1611 0.841309 53 4.68023 53 17.3143V45.9629C53 46.4213 53 46.851 52.9714 47.3094C52.8854 48.484 51.8254 49.4008 50.6795 49.3148C49.5049 49.2289 48.5881 48.1975 48.6741 47.0229C48.7027 46.6791 48.7027 46.3067 48.7027 45.9629V17.3143C48.7027 7.08671 46.7832 5.13861 36.527 5.13861H16.473C6.21676 5.13861 4.2973 7.08671 4.2973 17.3143V48.8278C4.2973 50.0024 3.32324 50.9764 2.14865 50.9764Z"
      fill={color}
    />
    <path
      d="M40.8243 62.4358H12.1757C5.47189 62.4358 0 56.9639 0 50.2601V48.398C0 42.6969 4.64108 38.0845 10.3135 38.0845H50.8513C52.0259 38.0845 53 39.0585 53 40.2331V50.2601C53 56.9639 47.5281 62.4358 40.8243 62.4358ZM10.3135 42.3818C6.99027 42.3818 4.2973 45.0747 4.2973 48.398V50.2601C4.2973 54.6147 7.82108 58.1385 12.1757 58.1385H40.8243C45.1789 58.1385 48.7027 54.6147 48.7027 50.2601V42.3818H10.3135Z"
      fill={color}
    />
    <path
      d="M37.9594 19.4628H15.0405C13.8659 19.4628 12.8918 18.4888 12.8918 17.3142C12.8918 16.1396 13.8659 15.1655 15.0405 15.1655H37.9594C39.134 15.1655 40.1081 16.1396 40.1081 17.3142C40.1081 18.4888 39.134 19.4628 37.9594 19.4628Z"
      fill={color}
    />
    <path
      d="M29.3648 29.4897H15.0405C13.8659 29.4897 12.8918 28.5156 12.8918 27.341C12.8918 26.1664 13.8659 25.1924 15.0405 25.1924H29.3648C30.5394 25.1924 31.5135 26.1664 31.5135 27.341C31.5135 28.5156 30.5394 29.4897 29.3648 29.4897Z"
      fill={color}
    />
  </svg>
);

export default ChapterIcon;
