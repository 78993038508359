import React from "react";
import { Link } from "react-router-dom";

const InfoBadge = ({
  numberText,
  text,
  badgeColor,
  numberColor,
  textColor,
  linkTo = "/",
}) => {
  const divStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "35px",
    backgroundColor: badgeColor,
    borderRadius: "50px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "16px",
    paddingRight: "16px",
  };

  const numberTextStyle = {
    fontSize: "28px",
    color: numberColor,
    marginRight: "12px",
  };

  const textStyle = {
    fontSize: "14px",
    color: textColor,
  };

  const linkStyle = {
    textDecoration: "none",
  };

  return (
    <Link style={linkStyle} to={linkTo}>
      <div className="infoBadge" style={divStyle}>
        <span style={numberTextStyle}>{numberText}</span>
        <span style={textStyle}>{text}</span>
      </div>
    </Link>
  );
};

export default InfoBadge;
