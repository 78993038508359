import React, { useState } from "react";
import { supabase } from "../../supabaseClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import BasePage from "../../components/general/BasePage";

const ResetPasswordPage = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      const { error } = await supabase.auth.updateUser({
        password: values.password,
      });

      if (error) {
        setMessage(error.message);
      } else {
        setMessage(
          "Password has been successfully reset! You've been automatically logged in!"
        );
      }

      setIsLoading(false);
      setSubmitting(false);
    },
  });

  return (
    <BasePage>
      <div className="container element-margin">
        <div className="inline-flex">
          <img
            src="/heart.png"
            style={{ height: "100px", width: "100px" }}
            alt="OWE Logo"
          />

          <p className="primary-font subtitle no-margin">
            Update your password
          </p>
          <form className="form-area" onSubmit={formik.handleSubmit}>
            <div>
              <input
                className="input-field"
                type="password"
                name="password"
                placeholder="New Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                tabIndex={1}
                autoComplete="new-password"
              />
            </div>
            <div className="element-margin">
              <input
                className="input-field"
                type="password"
                name="confirmPassword"
                placeholder="Confirm New Password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                tabIndex={2}
                autoComplete="new-password"
              />
            </div>
            {message && <div className="form-message">{message}</div>}
            <button
              className="button mid"
              type="submit"
              disabled={isLoading || formik.isSubmitting}
              tabIndex={3}
            >
              {isLoading ? "..." : "Update"}
            </button>
          </form>
          <div className="element-margin"></div>
        </div>
      </div>
    </BasePage>
  );
};

export default ResetPasswordPage;
