import React from "react";

import JoPaywall from "./JoPaywall";
import LauraPaywall from "./LauraPaywall";

export const PaywallSelector = ({ story }) => {
  if (story === "laura-the-bold" || story === "laura-epilogues") {
    return <LauraPaywall />;
  } else if (story === "josephine-on-fire") {
    return <JoPaywall />;
  }
  return null;
};

export default PaywallSelector;
