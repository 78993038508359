import React from "react";
import { Helmet } from "react-helmet";
import BasePage from "../../components/general/BasePage";
import mixpanel from "../../mixpanel";
import TitleCard from "../../components/landing/TitleCard";
import TextBlock from "../../components/landing/TextBlock";
import CTAImageOverlay from "../../components/landing/CTAImageOverlay";
import Spacer from "../../components/landing/Spacer";

export const Home = () => {
  const titleTexts = [
    {
      textAlignment: "center",
      text: "otherworlderotic",
      isBold: true,
      textStyle: "subtitle",
    },
    {
      textAlignment: "center",
      text: "uplifting illustrated, and very sexy stories from another world.",
      textStyle: "body",
      isHeader: true,
    },
  ];

  const infoTexts = [
    {
      textAlignment: "center",
      text: "Deep characters.",
      isBold: true,
      textStyle: "subtitle",
    },
    {
      textAlignment: "center",
      text: "Real-life storylines.",
      isBold: true,
      textStyle: "subtitle",
    },
    {
      textAlignment: "center",
      text: "Blisteringly hot erotica.",
      isBold: true,
      textStyle: "subtitle",
    },
    {
      textAlignment: "left",
      text: "My goal with otherworlderotic was to create a totally a new story format: full-length novels with hundreds of photo-real erotic illustrations.",
      textStyle: "body",
    },
    {
      textAlignment: "left",
      text: "I wanted to write erotica with realistic characters, deep experiences, and uncompromisingly hot, wholesome, and sex-positive action.",
      textStyle: "body",
    },
    {
      textAlignment: "left",
      text: "Jump in and see why my Patreon supporters support me in releasing new stories!",
      textStyle: "body",
    },
  ];

  const holderText = [
    {
      textAlignment: "center",
      text: " ",
      textStyle: "body",
    },
  ];

  const handleCTAClick = () => {
    mixpanel.track("CTA Click Home", {
      "test iteration": "no test",
      "test condition": "no condition",
    });
  };
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href={"https://www.otherworlderotic.com/assets/Laura-Hero.webp"}
          as="image"
        />
        {/* General tags */}
        <title>
          otherworlderotic: uplifting illustrated, and very sexy stories.
        </title>
        <meta
          name="description"
          content="Uplifting illustrated, and very sexy stories from another world. Choose which full-length story to dive into: a girl-next-door cutie, or a wild party girl?"
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.otherworlderotic.com/" />
        <meta name="author" content="Theo Hartley" />
        <meta name="robots" content="index,follow" />

        {/* OpenGraph tags */}
        <meta property="og:site_name" content="Otherworlderotic" />
        <meta
          property="og:title"
          content="Uplifting illustrated, and very sexy stories from another world. Choose which full-length story to dive into: a girl-next-door cutie, or a wild party girl?"
        />
        <meta property="og:type" content={"website"} />
        <meta
          property="og:image"
          content={"https://www.otherworlderotic.com/assets/Laura-Hero.webp"}
        />
        <meta
          property="og:description"
          content={
            "Uplifting illustrated, and very sexy stories from another world. Choose which full-length story to dive into: a girl-next-door cutie, or a wild party girl?"
          }
        />
        <meta property="og:url" content={"https://www.otherworlderotic.com/"} />

        {/* Schema.org tags for Google */}
        <meta
          itemProp="name"
          content="Uplifting illustrated, and very sexy stories from another world. Choose which full-length story to dive into: a girl-next-door cutie, or a wild party girl?"
        />
        <meta
          itemProp="description"
          content={
            "Uplifting illustrated, and very sexy stories from another world. Choose which full-length story to dive into: a girl-next-door cutie, or a wild party girl?"
          }
        />
        <meta
          itemProp="image"
          content={"https://www.otherworlderotic.com/assets/Laura-Hero.webp"}
        />

        {/* Schema.org structured data for Book */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            name: "Otherworlderotic Stories",
            description:
              "Uplifting illustrated, and very sexy stories from another world. Choose your adventure with our featured stories: Laura the Bold and Josephine on Fire.",
            url: "https://www.otherworlderotic.com/",
            about: {
              "@type": "CreativeWork",
              name: "Otherworlderotic",
              description:
                "Uplifting illustrated, and very sexy stories from another world.",
              author: {
                "@type": "Person",
                name: "Theo Hartley",
              },
            },
            hasPart: [
              {
                "@type": "Book",
                name: "Laura the Bold",
                url: "https://www.otherworlderotic.com/story/laura-the-bold/the-story",
                author: {
                  "@type": "Person",
                  name: "Theo Hartley",
                },
                image:
                  "https://www.otherworlderotic.com/assets/Laura-Hero.webp",
                description:
                  "A girl-next-door cutie's story of self discovery. Read Laura the Bold, an erotic illustrated story now!",
                publisher: {
                  "@type": "Organization",
                  name: "Hartley Publications, LLC",
                },
              },
              {
                "@type": "Book",
                name: "Josephine on Fire",
                url: "https://www.otherworlderotic.com/story/josephine-on-fire/the-story",
                author: {
                  "@type": "Person",
                  name: "Theo Hartley",
                },
                image:
                  "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20and%20Amy.webp",
                description:
                  "Love is hard, but getting laid should be easy. Read Josephine on Fire, the erotic illustrated story now!",
                publisher: {
                  "@type": "Organization",
                  name: "Hartley Publications, LLC",
                },
              },
            ],
            publisher: {
              "@type": "Organization",
              name: "Hartley Publications, LLC",
            },
          })}
        </script>
      </Helmet>
      <BasePage>
        <div className="mid-width">
          <TextBlock texts={titleTexts} signature={false} />
        </div>
        <div className="section two-column">
          <TitleCard
            imageUrl="https://www.otherworlderotic.com/assets/ssb-hero.jpg"
            height="850px"
            titleText="Sunkissed Summer Break"
            subtitleText="new chapter every friday!"
            chapterCount="6"
            imageCount="50"
            readsCount="9.8k"
            likesCount="2.5k"
            altText="The cover for the erotic story Sunkissed Summer Break, by Theo Hartley"
            linkTo={"/story/sunkissed-summer-break/chapter/1"}
            CTAtext="Read Now"
            onClick={handleCTAClick}
          />
          <div className="sm-show">
            <TextBlock texts={holderText} signature={true} />
          </div>
          <TitleCard
            imageUrl="https://www.otherworlderotic.com/assets/swordplay-cover-m.webp"
            height="850px"
            titleText="Lessons in Swordplay"
            subtitleText="someone has a lot to learn."
            chapterCount="8"
            imageCount="100"
            readsCount="78k"
            likesCount="5.8k"
            altText="The cover for Lessons in Swordplay, by Theo Hartley"
            CTAtext="Read Now"
            linkTo={"/story/lessons-in-swordplay/chapter/1"}
            onClick={handleCTAClick}
          />
        </div>
        <div className="section two-column">
          <TitleCard
            imageUrl="https://www.otherworlderotic.com/assets/Laura-Hero.webp"
            height="850px"
            titleText="Laura the Bold"
            subtitleText="an illustrated, wholesome, and very erotic story."
            chapterCount="30"
            imageCount="510"
            readsCount="310k"
            likesCount="11.3k"
            altText="The cover for the erotic story Laura the Bold, by Theo Hartley"
            linkTo={"/story/laura-the-bold/the-story"}
            CTAtext="Meet Laura"
            onClick={handleCTAClick}
          />
          <TitleCard
            imageUrl="https://www.otherworlderotic.com/assets/Jo-low-cut-dress.webp"
            height="850px"
            titleText="Josephine on Fire"
            subtitleText="an illustrated, wholesome and very erotic story."
            chapterCount="26"
            imageCount="240"
            readsCount="226k"
            likesCount="7.9k"
            altText="The cover for the erotic story Josephine on Fire, by Theo Hartley"
            CTAtext="Meet Jo"
            linkTo={"/story/josephine-on-fire/the-story"}
            onClick={handleCTAClick}
          />
          <div className="sm-show">
            <TextBlock texts={holderText} signature={true} />
          </div>
        </div>
        <div className="section two-column">
          <TitleCard
            imageUrl="https://www.otherworlderotic.com/assets/laura-lingerie.webp"
            height="850px"
            titleText="Laura: Epilogues"
            subtitleText="when you can't get enough of her."
            chapterCount="3"
            imageCount="80"
            readsCount="65k"
            likesCount="4.2k"
            altText="The cover for the epilogues for Laura the Bold, by Theo Hartley"
            CTAtext="Find Laura"
            linkTo={"/story/laura-epilogues/chapter/1"}
            onClick={handleCTAClick}
          />
          <TitleCard
            imageUrl="https://www.otherworlderotic.com/assets/dryad-nude.webp"
            height="850px"
            titleText="Music from Across the Water"
            subtitleText="an erotic fantasy short."
            chapterCount="1"
            imageCount="20"
            readsCount="14k"
            likesCount="1.3k"
            altText="The cover for Music from Across the Water, by Steven Ogilvy"
            CTAtext="Read Now"
            linkTo={"/story/music-from-across/chapter/1"}
            onClick={handleCTAClick}
          />
        </div>
        <div className="mid-width">
          <TextBlock texts={infoTexts} signature={true} />

          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/owe-story-images/josephine-on-fire/96_007_GLBYD.webp"
            height="750px"
            CTAText="Meet Amy"
            CTAColor="#860000"
            CTAFontColor="white"
            altText="Amy wearing a low cut elegant dress, with a beautiful expression on her face."
            linkTo={"/story/josephine-on-fire/the-story"}
            onClick={handleCTAClick}
          />
          <Spacer />
        </div>
      </BasePage>
    </>
  );
};

export default Home;
