import React from "react";
import { Link, useLocation } from "react-router-dom";
import mixpanel from "../../../mixpanel";

import NavBarButtons from "./navBarButtons";

const Footer = () => {
  const location = useLocation();
  const page = location.pathname + location.search;

  const patreonTrack = () => {
    mixpanel.track("Patreon Footer Click", {
      "Page URL": page,
    });
  };

  const redditTrack = () => {
    mixpanel.track("Reddit Footer Click", {
      "Page URL": page,
    });
  };

  const discordTrack = () => {
    mixpanel.track("Discord Footer Click", {
      "Page URL": page,
    });
  };

  return (
    <div className="header-footer-container">
      <div className="inline-flex">
        <div className="element-margin"></div>
        <div className="flex-space-between element-margin mid-media-column">
          <div className="flex-row-center-spaced mid-media-column">
            <Link
              to="/story/laura-the-bold/the-story"
              className="nav-link"
              style={{
                color: "white",
                fontFamily: "sans-serif",
                marginLeft: "15px",
                textDecoration: "underline",
              }}
            >
              Laura the Bold
            </Link>
            <div className="element-margin" />
            <Link
              to="/story/josephine-on-fire/the-story"
              className="nav-link"
              style={{
                marginLeft: "15px",
                color: "white",
                fontFamily: "sans-serif",
                textDecoration: "underline",
              }}
            >
              Josephine on Fire
            </Link>
            <div className="element-margin" />
            <a
              href="https://discord.gg/vp6hf8VDzh"
              className="nav-link"
              onClick={discordTrack}
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>
            <a
              href="https://www.reddit.com/r/otherworlderotic/"
              className="nav-link"
              onClick={redditTrack}
              target="_blank"
              rel="noopener noreferrer"
            >
              Reddit
            </a>
            <a
              href="https://www.patreon.com/otherworlderotic"
              className="nav-link"
              onClick={patreonTrack}
              target="_blank"
              rel="noopener noreferrer"
            >
              Patreon
            </a>
            <Link to="/about" className="nav-link">
              About
            </Link>
          </div>
          <div className="flex-row-center-spaced">
            <NavBarButtons showDash={true} showLogout={true} />
          </div>
        </div>
        <div className="primary-font element-margin undertitle">
          otherworlderotic is about sharing wholesome, consensual, and
          blisteringly hot illustrated erotic stories. All of the erotica here
          is fantasy.
        </div>
        <div className="primary-font accent-color element-margin undertitle">
          All rights reserved. All images have been artistically transformed by
          human hands. Any and all resemblance to real people, living or dead,
          is purely coincidental. © 2024. This website contains mature content
          that may be unsuitable for those under 18. By remaining on this
          website, you confirm that you are at least 18 years of age. If you are
          not, you may not continue to view the content on this site.
          <br />
        </div>
      </div>
    </div>
  );
};

export default Footer;
