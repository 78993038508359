import React from "react";
import { Link } from "react-router-dom";

const ChapterListItem = ({ chapter, story }) => {
  return (
    <div className="section two-column">
      <div className="container">
        <img
          className="image"
          src={chapter.thumbnail_image_url}
          alt={chapter.title}
        />
      </div>
      <div className="container tight-width">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <p className="title primary-color element-margin no-margin-top ">{`Chapter ${chapter.chapter_num}: ${chapter.title}`}</p>

          <div style={{ alignSelf: "flex-start", width: "100%" }}>
            <Link
              className="button arrow"
              to={`/story/${chapter.story_id}/chapter/${chapter.chapter_num}`}
            >
              {"Go"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChapterListItem;
