import React from "react";
import NavList from "../NavList";

const ChapterEnd = ({ chapter, story }) => {
  let nextChapter = story.chapter.find(
    (chap) => chap.chapter_num === chapter.chapter_num + 1
  );

  nextChapter = nextChapter ? nextChapter : chapter;

  return (
    <div className="section two-column">
      <div className="container stretch tight-width">
        <div style={{ maxWidth: "400px" }}>
          {!chapter.is_most_recent && (
            <div className="title secondary-font primary-color element-margin center-font">{`The story continues in Chapter ${nextChapter.chapter_num}...`}</div>
          )}
          {chapter.is_most_recent && (
            <div className="title secondary-font primary-color element-margin center-font">{`The story will continue very soon...`}</div>
          )}
        </div>
        <NavList story={story} chapter={chapter} />
      </div>

      <div className="container">
        <img
          className="image"
          src={nextChapter.thumbnail_image_url}
          alt={nextChapter.title}
        />
      </div>
    </div>
  );
};

export default ChapterEnd;
