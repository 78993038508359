import React, { useState } from "react";
import mixpanel from "../../mixpanel";
import { Link, useLocation } from "react-router-dom";

const ImageCensored = ({ src, alt, width = 512, height = 512 }) => {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const page = location.pathname + location.search;
  const handleSignUpClick = () => {
    mixpanel.track("Sign Up Censored Click", {
      "Page URL": page,
    });
  };

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const Skeleton = (
    <div
      className="skeleton"
      style={{ width: `${width}px`, height: `${height}px` }}
    ></div>
  );

  return (
    <div className="censored-container">
      {!loaded && Skeleton}
      <img
        className="censored-image"
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
      />
      <div className="censored-text subtitle mid-width">
        Sign up for free to reveal all images!
      </div>
      <Link
        to={"/signup"}
        className="buttonSmall fill censored-sign-up"
        onClick={handleSignUpClick}
      >
        {"Sign Up"}
      </Link>
    </div>
  );
};

export default ImageCensored;
