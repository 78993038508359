import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./SignUpCTA.module.css";
import mixpanel from "../../../mixpanel";

const SignUpCTA = () => {
  const location = useLocation();
  const page = location.pathname + location.search;

  const handleButtonClick = () => {
    mixpanel.track("Sign Up CTA Click", {
      "Page URL": page,
    });
  };

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <span className="title secondary-font primary-color element-margin">
          Sign up for free! Get a <b>bonus</b> content pack, uncensor images,
          and more!
        </span>
        <div style={{ height: "100px" }} />
        <a className="button" href={"/signup"} onClick={handleButtonClick}>
          Sign up
        </a>
      </div>
    </div>
  );
};

export default SignUpCTA;
