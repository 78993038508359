import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";

import BasePage from "../../components/general/BasePage";
import DashboardNavigator from "./DashboardNavigator";
import ContentPackStack from "./contentpackstack/ContentPackStack";

export const Dashboard = () => {
  const navigate = useNavigate();
  const { session } = useUser();
  const isAuthenticated = session !== null;
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const iconselection = "pack";
  const title = "My Packs";
  const subtitle =
    "Earn content packs for being there for certain story events, joining on Patreon, or even just making an account. When you earn one, it’s yours forever.";
  const imgUrl =
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/owe-story-images/laura-the-bold/27_001_QH3A9.webp";

  return (
    <BasePage>
      <DashboardNavigator
        iconselection={iconselection}
        title={title}
        subtitle={subtitle}
        imgUrl={imgUrl}
      />
      <ContentPackStack />
    </BasePage>
  );
};

export default Dashboard;
