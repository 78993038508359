import React from "react";

import supportedbypatreon from "../emblems/supportedbypatreon.svg";

const SupportedByPatreon = ({ link = true }) => {
  const patreonURL = "https://www.patreon.com/otherworlderotic";

  return link ? (
    <a
      href={patreonURL}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <img
        src={supportedbypatreon}
        alt="Supported by Patreon"
        className="element-margin"
      />
    </a>
  ) : (
    <img
      src={supportedbypatreon}
      alt="Supported by Patreon"
      className="element-margin"
    />
  );
};

export default SupportedByPatreon;
