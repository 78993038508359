import React from "react";
import { Link } from "react-router-dom";

const ContentPackListItem = ({ pack }) => {
  const { id, award_reason, title, cover_image_url } = pack;
  const infoContainerStyle = {
    position: "relative",
    backgroundImage: `url(${cover_image_url})`,
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    backgroundPosition: "center",
    borderRadius: "var(--border-radius-md)",
    height: "512px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  return (
    <div className="container">
      <div style={infoContainerStyle} className="overlay-container">
        <div
          className="mid-width"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="element-margin-padding">
            {/* Top-aligned content */}
            <div>
              <span className="mid-title secondary-font primary-color element-margin">
                {title}
              </span>
              <div />
              <span className="body primary-font accent-color">
                <strong>Pack earned from:</strong> {award_reason}
              </span>
            </div>
          </div>
          <div className="element-margin-padding">
            {/* Bottom-aligned content */}
            <div>
              <Link to={`/contentpack/${id}`} className="button arrow">
                Go
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentPackListItem;
