import React from "react";
import BasePage from "./BasePage";

const LoadingIndicator = () => {
  return (
    <BasePage>
      <div className="spin" />
    </BasePage>
  );
};

export default LoadingIndicator;
