import React from "react";

import Header from "./nav/Header";
import Footer from "./nav/Footer";

const BasePage = ({ children }) => {
  return (
    <div className="page">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default BasePage;
