import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import mixpanel from "./mixpanel";

const MixpanelAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    const queryParams = new URLSearchParams(location.search);

    // Convert URLSearchParams to a plain object
    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    // Tracking the page view with URL parameters as separate properties
    mixpanel.track("Page View", {
      "Page URL": page,
      ...params,
    });

    mixpanel.time_event("Page Time Spent");

    // This function will be called when the page is about to unload
    const handleBeforeUnload = () => {
      mixpanel.track("Page Time Spent", {
        "Page URL": page,
        ...params,
      });
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      mixpanel.track("Page Time Spent", {
        "Page URL": page,
        ...params,
      });
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname, location.search]);

  return null;
};

export default MixpanelAnalytics;
