import React from "react";
import { Link } from "react-router-dom";

const InLineImage = ({ linkTo, imageURL, altText }) => {
  return (
    <Link to={linkTo}>
      <img
        src={imageURL}
        alt={altText}
        style={{
          width: "100%",
          height: "auto",
          borderRadius: "10px",
        }}
      />
    </Link>
  );
};

export default InLineImage;
