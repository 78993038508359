import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import BasePage from "../../components/general/BasePage";

const LoginPage = () => {
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      const { error } = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password,
      });

      if (error) {
        setLoginError(error.message);
      }

      if (!error) {
        const from = location.state?.from || "/";
        navigate(from);
      }

      setIsLoading(false);
      setSubmitting(false);
    },
  });

  return (
    <BasePage>
      <div className="container element-margin">
        <div className="inline-flex">
          <div></div>
          <img
            src="/heart.png"
            style={{ height: "100px", width: "100px" }}
            alt="OWE Logo"
          />

          <p className="primary-font subtitle no-margin">
            Login to otherworlderotic
          </p>
          <form className="form-area" onSubmit={formik.handleSubmit}>
            <div>
              <input
                className="input-field"
                type="email"
                name="email"
                placeholder="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                tabIndex={1}
                autoComplete="email"
              />
            </div>
            <div className="element-margin">
              <input
                className="input-field"
                type="password"
                name="password"
                placeholder="*********"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                tabIndex={2}
                autoComplete="current-password"
              />
            </div>
            {loginError && <div className="form-error">{loginError}</div>}
            <button
              className="button mid "
              type="submit"
              disabled={isLoading || formik.isSubmitting}
              tabIndex={3}
            >
              {isLoading ? "..." : "Login"}
            </button>
          </form>
          <div className="element-margin" />
          <Link
            to={"/forgotpassword"}
            className="primary-font caption element-margin accent-color underline"
          >
            Forgot password?
          </Link>
          <div className="element-margin" />
          <div className="element-margin" />
          <div className="line-div" />
          <p className="primary-font caption element-margin accent-color">
            Don&apos;t have an account?
          </p>

          <Link to={"/signup"} className="buttonSmall element-margin">
            Sign up
          </Link>
        </div>
      </div>
    </BasePage>
  );
};

export default LoginPage;
