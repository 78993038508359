import React from "react";

const SpacerLine = () => {
  return (
    <div
      style={{
        height: 2,
        width: "80%",
        margin: "20px",
        backgroundColor: "gray",
      }}
    />
  );
};

export default SpacerLine;
