import React, { useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import BasePage from "../../components/general/BasePage";

const SignupScreen = () => {
  const [signupError, setSignupError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      privacyPolicyConsent: false,
      ageVerification: false,
      marketingConsent: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("required"),
      privacyPolicyConsent: Yup.boolean().oneOf(
        [true],
        "Accept the Privacy Policy to continue."
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);

      const { error } = await supabase.auth.signUp({
        email: values.email,
        password: values.password,
      });

      if (error) {
        setSignupError(error.message);
      } else {
        setSignupError("Check your email for the verification link.");
      }

      setIsLoading(false);
      setSubmitting(false);
    },
  });

  return (
    <BasePage>
      <div className="container element-margin">
        <div className="inline-flex">
          <div></div>
          <img
            src="/heart.png"
            style={{ height: "100px", width: "100px" }}
            alt="OWE Logo"
          />
          <p className="primary-font subtitle no-margin">
            Welcome to otherworlderotic!
          </p>
          <p className="primary-font body element-margin accent-color">
            Join for a bonus content pack.
          </p>
          <form className="form-area" onSubmit={formik.handleSubmit}>
            <div>
              <input
                className="input-field"
                type="email"
                name="email"
                placeholder="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                tabIndex={1}
                autoComplete="email"
              />
            </div>
            <div className="element-margin">
              <input
                className="input-field"
                type="password"
                name="password"
                placeholder="*********"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                tabIndex={2}
                autoComplete="current-password"
              />
            </div>
            <div className="element-margin">
              <input
                type="checkbox"
                name="privacyPolicyConsent"
                className="large-checkbox"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.privacyPolicyConsent}
                tabIndex={3}
              />
              <label htmlFor="privacyPolicyConsent" className="privacy-label">
                I accept the{" "}
                <Link to="/privacy" className="privacy-link">
                  Privacy Policy
                </Link>{" "}
                and the{" "}
                <Link to="/terms" className="privacy-link">
                  Terms and Conditions
                </Link>{" "}
                and am 18 years or older
              </label>
            </div>
            {signupError && <div className="form-error">{signupError}</div>}
            <button
              className="button mid"
              type="submit"
              disabled={isLoading || formik.isSubmitting}
              tabIndex={4}
            >
              {isLoading ? "..." : "Sign Up"}
            </button>
          </form>
          <div className="element-margin"></div>
          <div className="element-margin"></div>
          <div className="line-div" />
          <p className="primary-font caption element-margin accent-color">
            Already have an account?
          </p>
          <Link to={"/login"} className="buttonSmall element-margin">
            Login
          </Link>
        </div>
      </div>
    </BasePage>
  );
};

export default SignupScreen;
