import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BasePage from "../../components/general/BasePage";
import LoadingIndicator from "../../components/general/LoadingIndicator";

const EmailPreferences = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const header = "You have been unsubscribed.";
  const body =
    "If you'd like to resubscribe, please log in and manage your subscriptions on the dashboard.";

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const userId = params.get("user_id");
    const newsletter = params.get("newsletter");
    const chapter = params.get("chapter");
    const pack = params.get("pack");

    const isValidBoolean = (val) => ["true", "false", null].includes(val);

    if (!userId) {
      navigate("/");
      return;
    }

    if (
      !isValidBoolean(newsletter) ||
      !isValidBoolean(chapter) ||
      !isValidBoolean(pack)
    ) {
      navigate("/");
      return;
    }

    // Construct the URL with only provided preferences
    let url = `/api/emailsubscriptionhandler?user_id=${userId}`;
    if (newsletter !== null) url += `&newsletter=${newsletter}`;
    if (chapter !== null) url += `&chapter=${chapter}`;
    if (pack !== null) url += `&pack=${pack}`;

    // Call the Vercel serverless function
    fetch(url)
      .then((response) => {
        if (response.ok) {
          setSuccess(true);
        } else {
          navigate("/");
        }
      })
      .catch(() => {
        navigate("/");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location, navigate]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (success) {
    return (
      <BasePage>
        <div className="section">
          <div className="container centering mid-width">
            <p className="subtitle primary-font primary-color bold">{header}</p>
            <p className="body primary-font primary-color">{body}</p>
          </div>
        </div>
      </BasePage>
    );
  }

  return null;
};

export default EmailPreferences;
