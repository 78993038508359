import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { supabase } from "../../supabaseClient";

import BasePage from "../../components/general/BasePage";
import SectionStack from "../chapter/SectionStack";
import LoadingIndicator from "../../components/general/LoadingIndicator";

export const Pack = () => {
  const { contentPackId } = useParams();
  const [contentPackData, setContentPackData] = useState(null);

  useEffect(() => {
    const fetchContentPackData = async () => {
      const { data, error } = await supabase
        .from("pack_section")
        .select(`id, content, type`)
        .eq("pack_id", contentPackId)
        .order("order_num", { ascending: true });
      if (error) {
        console.error("Error fetching data:", error);
        return;
      }
      setContentPackData(data);
    };

    fetchContentPackData();
  }, [contentPackId]);

  if (!contentPackData) {
    return <LoadingIndicator />;
  }

  return (
    <BasePage>
      <Link to={`/dashboard`} className="button">
        {"To Dashboard"}
      </Link>
      <div className="element-margin"></div>
      <SectionStack sections={contentPackData} />
      <Link to={`/dashboard`} className="button">
        {"To Dashboard"}
      </Link>
      <div className="element-margin"></div>
    </BasePage>
  );
};
export default Pack;
