import React from "react";

import mixpanel from "../../mixpanel";
import { Helmet } from "react-helmet";

import BasePage from "../../components/general/BasePage";
import TextBlock from "../../components/landing/TextBlock";
import TitleCard from "../../components/landing/TitleCard";
import GalleryOverlay from "../../components/landing/GalleryOverlay";
import ImageFadeText from "../../components/landing/ImageFadeText";
import CTAImageOverlay from "../../components/landing/CTAImageOverlay";
import CTABadge from "../../components/landing/CTABadge";
import InLineImage from "../../components/landing/InLineImage";
import SpacerLine from "../../components/landing/SpacerLine";
import Spacer from "../../components/landing/Spacer";

export const JoLanding = () => {
  // Define test image URLs
  const imageUrlA =
    "https://www.otherworlderotic.com/assets/Jo-low-cut-dress.webp";
  const imageUrlB =
    "https://www.otherworlderotic.com/assets/Jo-low-cut-dress.webp";

  // Get the current hour using the JavaScript Date object
  const currentHour = new Date().getHours();
  // Determine if the current hour is even or odd
  const isEvenHour = currentHour % 2 === 0;

  // Based on the hour, set the appropriate image URL and test condition
  const imageUrl = isEvenHour ? imageUrlA : imageUrlB;
  const testIteration = "JO_ST_001";
  const testCondition = isEvenHour ? "A - Amy Cover" : "B - low cut dress";

  const handleCTAClick = () => {
    mixpanel.track("CTA Click", {
      "test iteration": testIteration,
      "test condition": testCondition,
      "landing page": "JoSt",
    });
  };

  const rootCTAURL = "/story/josephine-on-fire/chapter/1";
  const spoilerCTAURL = "/josephine-on-fire/erotica-preview";

  const fadeText = `
..My underwear follows suit, leaving me standing completely bare in the soft glow of my room.
Time to get warmed up.
I arrange the pillows to support my back, and then I start running a hand down the front of my body, my other hand reaching for the toy I had set aside.
"Time for some 'me time'," I whisper into the quiet room, my eyes gleaming with excitement as my fingers...
  `;

  const featuresBlock = [
    {
      textAlignment: "left",
      text: "The full-length story features:",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "• an erotic story with fully nude pictures",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• a wholesome story about a sexual awakening",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• light conflict",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• (mostly) monogamy",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• open door sex scenes",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• a happily ever after!",
      textStyle: "undertitle",
    },
  ];

  const infoText = [
    {
      textAlignment: "left",
      text: "Love is hard to find, but getting laid should be easy...",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Don't you dare call her Josephine; her name is Jo. College was supposed to be about getting laid, ideally all the time. But is something more meaningful staring her in the face?",
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "Does Amy have the courage to actually make a move?",
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "Does Jo even like girls?",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "Featuring nude images, a sexually liberated lead, friends-to-lovers, and lots of spice.",
      textStyle: "undertitle",
    },
  ];

  const testimonials1 = [
    {
      textAlignment: "left",
      text: "\"I just found your site and I SPED through everything you've published so far. As a straight man, I didn't know this was something I was looking for but I absolutely love the mix of real storytelling and eroticism.\"",
      isItalic: true,
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "-u/laydownthebunt on Reddit",
      textStyle: "undertitle",
    },
  ];

  const testimonials2 = [
    {
      textAlignment: "left",
      text: '"Jo is my favorite. Holy shit she is just the right amount of crazy..."',
      isItalic: true,
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "-Gardio on Discord",
      textStyle: "undertitle",
    },
  ];

  const testimonials3 = `"I love the story within a story that Amy is writing... I've been looking for some erotica with this sort of depth for a long, long time. Plus, the pictures!!"
  -u/ricky311 on Reddit`;

  const helperText = [
    {
      textAlignment: "left",
      text: "Amy's a lot more reserved than Jo, will the two make good friends?",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "... More than friends?",
      textStyle: "body",
    },
    {
      textAlignment: "center",
      text: "I'm a one man team funded entirely by my generous patrons!",
      isItalic: true,
      textStyle: "body",
    },
  ];

  const spoilerText = [
    {
      textAlignment: "center",
      text: "The build up is worth it, but if you want to skip to the action...",
      textStyle: "body",
    },
  ];

  const authorText = [
    {
      textAlignment: "left",
      text: "About the Author",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Theo Hartley writes the kind of erotic stories he wants to read: featuring realistic characters, happy relationships, slow-burn, blisteringly hot sex.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "Sex is so much better when there's realistic, rich characters and real emotional stakes.",
      isBold: true,
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Real romance, imaginary people.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "The fully explicit, photo-realistic illustrations that go with the story are created with digital art tools, photoshop, and open source AI tools. Jo and her friends aren’t based on any real people or their likeness.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "These characters are unique to these sexy illustrated erotic stories, you can only find them here on otherworlderotic.",
      textStyle: "undertitle",
    },
  ];

  const redheadText = [
    {
      textAlignment: "center",
      text: "Or maybe the girl next door is more your type...",
      isBold: true,
      textStyle: "subtitle",
    },
  ];

  const storyInfoTextOne = [
    {
      textAlignment: "left",
      text: "Lesbian erotica grounded in reality",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "This story is different from what you typically get with erotica. Here, the characters feel like real people and their connections are as genuine as the ones you know.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "No overdone theatrics; just a compelling, down to earth story. And of course, an illustrated erotic story brings extra spice. Get wrapped up in a erotica where the sparks fly without needing to resort to tired tropes.",
      textStyle: "undertitle",
    },
  ];

  const storyInfoTextTwo = [
    {
      textAlignment: "left",
      text: "Positive and wholesome",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Illustrated erotica is hard to come by, especially where the heat is matched by heart. These stories get explicit, but they're consensual encounters that not only sizzle but also celebrate the joy of a positive, respectful connection. It's an erotic story that's as much about the chemistry as it is about the caring. Sex is better when there's a partnership built on trust and mutual pleasure.",
      textStyle: "undertitle",
    },
  ];

  const storyInfoTextThree = [
    {
      textAlignment: "left",
      text: "Novel-length erotic stories with a visual component",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "NSFW AI allowed the creation of this new form factor of erotica, similar to porn comics, but with a lot more substance. These illustrated stories feature the emotional intensity you can only get through a book, yet elevate the experience with enough pictures to keep things... stimulated.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "If you enjoy adult comics or the deep, character-driven storytelling of a novel, you'll love this. The story has a sophisticated twist on erotic enjoyment for those seeking substance with their sizzle.",
      textStyle: "undertitle",
    },
  ];

  const storyInfoTextFour = [
    {
      textAlignment: "left",
      text: "A note from the author",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Josephine on Fire is releasing every week! I love working on the next installment of what Jo and Amy get up to every day.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "If you're looking for more, I'm already deep into creating new illustrated erotica for otherworlderotic, and you can check out 'Laura the Bold' for a complete erotic illustrated story!",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "I hope you enjoy Josephine on Fire!",
      textStyle: "undertitle",
    },
  ];

  const galleryImages = [
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Amy%20stunner%20shot.webp",
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Bren%20nude%20outdoors.webp",
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Laura%20Shower.webp",
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20Top%20Down%20Rug.webp",
  ];

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href={"https://www.otherworlderotic.com/assets/Jo-low-cut-dress.webp"}
          as="image"
        />
        <link
          rel="preload"
          href={"https://www.otherworlderotic.com/assets/Jo-and-Amy.webp"}
          as="image"
        />
        {/* General tags */}
        <title>
          Josephine on Fire: an illustrated erotic story from otherworlderotic
        </title>
        <meta
          name="description"
          content="Love is hard, but getting laid should be easy. Read Josephine on Fire, the erotic illustrated story now!"
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="canonical"
          href="https://www.otherworlderotic.com/story/josephine-on-fire/landing"
        />
        <meta name="author" content="Theo Hartley" />
        <meta name="robots" content="index,follow" />

        {/* OpenGraph tags */}
        <meta property="og:site_name" content="Otherworlderotic" />
        <meta
          property="og:title"
          content="Josephine on Fire: an illustrated erotic story from otherworlderotic"
        />
        <meta property="og:type" content={"book"} />
        <meta
          property="og:image"
          content={
            "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20and%20Amy.webp"
          }
        />
        <meta
          property="og:description"
          content={
            "Love is hard, but getting laid should be easy. Read Josephine on Fire, the erotic illustrated story now!"
          }
        />
        <meta
          property="og:url"
          content={
            "https://www.otherworlderotic.com/story/josephine-on-fire/the-story"
          }
        />

        {/* Schema.org tags for Google */}
        <meta
          itemProp="name"
          content="Josephine on Fire: an illustrated erotic story from otherworlderotic"
        />
        <meta
          itemProp="description"
          content={
            "Love is hard, but getting laid should be easy. Read Josephine on Fire, the erotic illustrated story now!"
          }
        />
        <meta
          itemProp="image"
          content={
            "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20and%20Amy.webp"
          }
        />

        {/* Schema.org structured data for Book */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Book",
            name: "Josephine on Fire",
            url: "https://www.otherworlderotic.com/story/josephine-on-fire/the-story",
            author: {
              "@type": "Person",
              name: "Theo Hartley",
            },
            image:
              "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20and%20Amy.webp",
            description:
              "Love is hard, but getting laid should be easy. Read Josephine on Fire, the erotic illustrated story now!",
            publisher: {
              "@type": "Organization",
              name: "Hartley Publications, LLC",
            },
          })}
        </script>
      </Helmet>
      <BasePage>
        <div className="page mid-width">
          <TitleCard
            imageUrl={imageUrl}
            height="850px"
            titleText="Josephine on Fire"
            subtitleText="an illustrated, wholesome and very erotic story."
            chapterCount="20"
            imageCount="240"
            readsCount="67k"
            likesCount="1.2k"
            altText="The cover for the erotic story Josephine on Fire, by Theo Hartley"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <GalleryOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20dark%20nude%20seductive.webp"
            height="950px"
            titleText="Jo's a bit wild"
            subtitleText='... but the story features some "friends" too.'
            images={galleryImages}
            altText="Jo nude in the dark, leaning forward. A censored image."
          />

          <Spacer height="10px" />
          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <Spacer />

          <ImageFadeText
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20touching%20self.webp"
            height="900px"
            text={fadeText}
          />

          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <Spacer />

          <TextBlock container={true} texts={featuresBlock} />
          <Spacer />

          <InLineImage
            linkTo={rootCTAURL}
            imageURL="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20dark%20nude.webp"
            altText="Jo in the dark with a seductive expression, nude, with explicit content out of frame."
          />

          <TextBlock container={false} texts={testimonials1} />

          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <Spacer height="5px" />

          <TextBlock container={false} texts={testimonials2} />

          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Amy%20elegant%20dress.webp"
            height="750px"
            CTAText="Meet Amy"
            CTAColor="#860000"
            CTAFontColor="white"
            altText="Amy wearing a low cut elegant dress, with a beautiful expression on her face."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <TextBlock container={false} texts={helperText} signature={true} />

          <ImageFadeText
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Bren%20nude%20in%20river.webp"
            height="600px"
            text={testimonials3}
            gradient=".6"
          />

          <Spacer />

          <TextBlock container={true} texts={infoText} />

          {false && <SpacerLine />}
          {false && <TextBlock container={false} texts={spoilerText} />}
          <Spacer />
          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <Spacer />
          {false && (
            <CTABadge
              text="A sexy spoiler"
              badgeColor="#860000"
              textColor="white"
              paddingRight="10px"
              linkTo={spoilerCTAURL}
              onClick={handleCTAClick}
            />
          )}
          {false && <SpacerLine />}

          <TextBlock container={true} texts={authorText} signature={true} />
          <Spacer />
          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <SpacerLine />

          <TextBlock container={false} texts={redheadText} />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Laura%20cutie.webp"
            height="850px"
            CTAText="Meet Laura"
            CTAColor="#860000"
            CTAFontColor="white"
            altText="Laura in cozy clothes, looking at the camera romantically."
            linkTo={"/story/laura-the-bold/the-story"}
            onClick={handleCTAClick}
          />
          <SpacerLine />

          <TextBlock container={true} texts={storyInfoTextOne} />
          <Spacer />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20underwear%20on%20bed.webp"
            height="750px"
            CTAText="Read Now"
            CTAColor="#4A39B6"
            CTAFontColor="white"
            altText="Jo wearing underwear sitting on a dorm room bed."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <TextBlock container={true} texts={storyInfoTextTwo} />
          <Spacer />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Bren%20nude%20bathhouse.webp"
            height="750px"
            CTAText="Read Now"
            CTAColor="#4A39B6"
            CTAFontColor="white"
            altText="Bren nude in a bathhouse, censored image."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <TextBlock container={true} texts={storyInfoTextThree} />
          <Spacer />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Amy%20cafe%20smile.webp"
            height="750px"
            CTAText="Read Now"
            CTAColor="#4A39B6"
            CTAFontColor="white"
            altText="Amy sitting in a cafe with a radiant smile on her face."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <TextBlock
            container={true}
            texts={storyInfoTextFour}
            signature={true}
          />
          <Spacer />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Jo%20booty%20shot.webp"
            height="750px"
            CTAText="Read Now"
            CTAColor="#4A39B6"
            CTAFontColor="white"
            altText="Jo nude an an art studio, shot from behind."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
        </div>
      </BasePage>
    </>
  );
};

export default JoLanding;
