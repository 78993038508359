import React, { useEffect, useState } from "react";
import { supabase } from "../../../supabaseClient";

import ContentPackListItem from "./ContentPackListItem";

const ContentPackStack = () => {
  const [packs, setPacks] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchPacks = async () => {
      const { data, error } = await supabase
        .from("user_pack")
        .select(`pack (id, award_reason, title, cover_image_url)`)
        .order("awarded_at", { ascending: false });
      if (error) {
        console.error("Error fetching data:", error);
        return;
      }
      setPacks(data);
    };

    fetchPacks();
  }, []);
  let columns = "repeat(2, 1fr)";
  if (windowWidth <= 1030) columns = "1fr";

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: columns,
        gap: "10px",
        marginBottom: "16px",
      }}
    >
      {packs.map((pack) => (
        <ContentPackListItem key={pack.pack.id} pack={pack.pack} />
      ))}
    </div>
  );
};

export default ContentPackStack;
