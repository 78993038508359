import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import ImageWithSkeleton from "../../components/general/ImageWithSkeleton";

const FavoritesGallery = () => {
  const [favorites, setFavorites] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchFavorites = async () => {
      const { data, error } = await supabase
        .from("user_favorites")
        .select(`section (id, content)`)
        .order("created_at", { ascending: false });
      if (error) {
        console.error("Error fetching data:", error);
        return;
      }
      setFavorites(data);
    };

    fetchFavorites();
  }, []);

  const totalPages = Math.ceil(favorites.length / 4);
  const currentFavorites = favorites.slice(
    currentPage * 4,
    (currentPage + 1) * 4
  );

  let columns = "repeat(2, 1fr)";
  if (windowWidth <= 1030) columns = "1fr";

  return (
    <div className="element-margin section column">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: columns,
          gap: "10px",
          marginBottom: "16px",
        }}
      >
        {currentFavorites.map((favorite) => (
          <ImageWithSkeleton
            src={favorite.section.content}
            alt={`Favorite ${favorite.section.id}`}
            key={favorite.section.id}
            section_id={favorite.section.id}
            favoriteState={true}
          />
        ))}
      </div>
      <div className="flex-space-between tight-width centering">
        <button
          className="circleButton circle"
          onClick={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
          disabled={currentPage === 0}
        >
          {"<"}
        </button>
        <span className="mid-title primary-font">
          {currentPage + 1} / {totalPages}
        </span>
        <button
          className="circleButton circle"
          onClick={() =>
            setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1))
          }
          disabled={currentPage === totalPages - 1}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default FavoritesGallery;
