import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../UserContext";

import PatreonCTA from "./PatreonCTA";
import PatreonThanks from "./PatreonThanks";

import BackArrowIcon from "../../components/emblems/BackArrowIcon";
import ChapterIcon from "../../components/emblems/ChapterIcon";
import HeartIcon from "../../components/emblems/HeartIcon";
import SettingsIcon from "../../components/emblems/SettingsIcon";

export const DashboardNavigator = ({ iconselection, title, subtitle }) => {
  const { entitlement } = useUser();

  // Determine the color based on the iconselection prop
  const getIconColor = (iconName) => {
    return iconName === iconselection ? "var(--primary)" : "white";
  };

  return (
    <div className="section two-column">
      <div className="container stretch tight-width">
        <div>
          <div className="chapter-link-container">
            <Link to={`/`}>
              <div className="chapter-link">
                <BackArrowIcon />
              </div>
            </Link>
            <p className="title primary-font accent-color element-margin">
              Dashboard
            </p>
          </div>

          <div className="horizontal-space-between">
            <Link to={"/dashboard"} className="chapter-link">
              <ChapterIcon color={getIconColor("pack")} />
            </Link>
            <Link to={"/dashboard/favorites"} className="chapter-link">
              <HeartIcon color={getIconColor("favorites")} />
            </Link>
            <Link to={"/dashboard/settings"} className="chapter-link">
              <SettingsIcon color={getIconColor("settings")} />
            </Link>
          </div>

          <div className="div-line" />
          <p className="title primary-font primary-color element-margin center-font">
            {title}
          </p>
          <p className="body primary-font secondary-color element-margin center-font">
            {subtitle}
          </p>
        </div>
      </div>

      <div className="container">
        {entitlement !== "INACTIVE" ? <PatreonThanks /> : <PatreonCTA />}
      </div>
    </div>
  );
};

export default DashboardNavigator;
