import React from "react";

const ImageGallery = ({ images }) => {
  const galleryStyle = {
    display: "flex",
    overflowX: "auto",
    gap: "15px",
    padding: "10px 0",
    position: "absolute",
    bottom: "10px",
    left: "0px",
    right: "0px",
    background: "rgba(0, 0, 0, 0)",
  };

  return (
    <div style={galleryStyle} className="hide-scrollbar">
      {images.map((image, index) => (
        <div
          key={index}
          style={{
            borderRadius: "10px",
            minWidth: "200px",
            height: "200px",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      ))}
    </div>
  );
};

export default ImageGallery;
