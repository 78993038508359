import React, { useEffect } from "react";
import BasePage from "../components/general/BasePage";
import TextBlock from "../components/landing/TextBlock";
import Spacer from "../components/landing/Spacer";
import OverlayCard from "../components/landing/OverlayCard";

export const PatreonRedirect = () => {
  useEffect(() => {
    window.open("https://www.patreon.com/otherworlderotic", "_blank");
  }, []);

  const featuresBlock = [
    {
      textAlignment: "center",
      text: "Joined on Patreon?",
      textStyle: "title",
    },
    {
      textAlignment: "left",
      text: "Refresh and you'll have access to all stories on your account!",
      textStyle: "caption",
    },
    {
      textAlignment: "center",
      text: "If you have any issues, reach out to me and I'll get things figured out right away!",
      isBold: true,
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "You can reach me on Patreon messenger or Discord (otherworlderotic)!",
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "Thank you!",
      textStyle: "subtitle",
    },
  ];

  return (
    <BasePage>
      <div className="page mid-width">
        <OverlayCard
          imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20College%20Party.webp"
          height="750px"
          titleText="Thank you!"
          subtitleText="Your support on Patreon makes this possible!"
          altText="Thank you! From Theo Hartley"
          linkTo={"/"}
          CTAtext="Back to stories"
        />
        <Spacer />
        <TextBlock container={false} texts={featuresBlock} signature={true} />
      </div>
    </BasePage>
  );
};

export default PatreonRedirect;
