import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserProvider } from "./UserContext";
import { StoryProvider } from "./StoryContext";

import MixpanelAnalytics from "./MixpanelAnalytics";
import ScrollToTop from "./ScrollToTop";

import Home from "./pages/home/Home";
import Story from "./pages/story/Story";
import Chapter from "./pages/chapter/Chapter";
import Pack from "./pages/pack/Pack";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardFavorites from "./pages/dashboard/DashboardFavorites";
import DashboardSettings from "./pages/dashboard/DashboardSettings";
import About from "./pages/about/About";

import PrivacyPolicy from "./pages/util/PrivacyPolicy.js";
import Terms from "./pages/util/Terms.js";
import NotFound from "./pages/util/NotFound";
import SignupPage from "./pages/util/SignupPage";
import LoginPage from "./pages/util/LogInPage";
import ForgotPasswordPage from "./pages/util/ForgotPassword";
import ResetPasswordPage from "./pages/util/ResetPassword";
import EmailPreferences from "./pages/util/EmailPreferences";
import LauraLanding from "./pages/story-landing/LauraLanding";
import JoLanding from "./pages/story-landing/JoLanding";
import JoPaywall from "./pages/paywall/JoPaywall";
import PatreonRedirect from "./pages/patreonRedirect";
import CreditCard from "./pages/CreditCard";
import LauraPaywall from "./pages/paywall/LauraPaywall";
import CCTest from "./pages/CCtest";

export const App = () => {
  return (
    <Router>
      <UserProvider>
        <StoryProvider>
          <ScrollToTop />
          <MixpanelAnalytics />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<About />} />
            <Route path="/story/:storyId" element={<Story />} />
            <Route
              path="/story/:storyId/chapter/:chapterNum"
              element={<Chapter />}
            />
            <Route path="/contentpack/:contentPackId" element={<Pack />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/dashboard/favorites"
              element={<DashboardFavorites />}
            />
            <Route path="/dashboard/settings" element={<DashboardSettings />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/email-preference" element={<EmailPreferences />} />
            <Route path="/patreon" element={<PatreonRedirect />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/cc-payment" element={<CreditCard />} />
            <Route path="/cc-test" element={<CCTest />} />
            <Route
              path="/story/laura-the-bold/the-story"
              element={<LauraLanding />}
            />
            <Route
              path="/story/josephine-on-fire/the-story"
              element={<JoLanding />}
            />

            <Route
              path="/story/josephine-on-fire/join-patreon"
              element={<JoPaywall />}
            />
            <Route
              path="/story/laura-the-bold/join-patreon"
              element={<LauraPaywall />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </StoryProvider>
      </UserProvider>
    </Router>
  );
};
