import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";
import { supabase } from "../../supabaseClient";

import BasePage from "../../components/general/BasePage";
import TextBlock from "../../components/landing/TextBlock";
import DashboardNavigator from "./DashboardNavigator";
import ReactSwitch from "react-switch";

export const DashboardSettings = () => {
  const navigate = useNavigate();
  const { session } = useUser();
  const isAuthenticated = session !== null;

  const [newsletter, setNewsletter] = useState(false);
  const [chapter, setChapter] = useState(false);
  const [pack, setPack] = useState(false);

  const infoTexts = [
    {
      textAlignment: "center",
      text: "For help or if you have any feedback, reach out to me at otherworlderotic (at) gmail.com",
      isBold: true,
      textStyle: "subtitle",
    },
  ];

  useEffect(() => {
    const fetchPreferences = async () => {
      const { data, error } = await supabase
        .from("profiles_comms")
        .select(`newsletter, chapter, pack`)
        .single();

      if (data && !error) {
        setNewsletter(data.newsletter);
        setChapter(data.chapter);
        setPack(data.pack);
      }
    };

    fetchPreferences();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleToggle = async (preferenceType, newValue) => {
    const updateObject = {};
    updateObject[preferenceType] = newValue;
    const userId = session.user.id;

    try {
      const { error } = await supabase
        .from("profiles_comms")
        .update(updateObject)
        .eq("id", userId);

      if (error) {
        console.error("Error updating preferences:", error.message);
      } else {
        if (preferenceType === "newsletter") setNewsletter(newValue);
        else if (preferenceType === "chapter") setChapter(newValue);
        else if (preferenceType === "pack") setPack(newValue);
      }
    } catch (err) {
      console.error("There was an unexpected error:", err.message);
    }
  };

  const iconselection = "settings";
  const title = "Settings";
  const subtitle = "";

  return (
    <BasePage>
      <DashboardNavigator
        iconselection={iconselection}
        title={title}
        subtitle={subtitle}
      />
      <div className="element-margin section column">
        <div className="container">
          <p className="subtitle primary-font primary-color bold">
            Email Preferences
          </p>
          <div className="preference-container primary-font primary-color body">
            <div className="preference-item">
              <div className="preference-label">
                <span>Newsletter (~quarterly): </span>
              </div>
              <ReactSwitch
                checked={newsletter}
                onChange={(checked) => handleToggle("newsletter", checked)}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="newsletter-switch"
              />
            </div>
            <div className="preference-item">
              <div className="preference-label">
                <span>Every new chapter (weekly): </span>
              </div>
              <ReactSwitch
                checked={chapter}
                onChange={(checked) => handleToggle("chapter", checked)}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="chapter-switch"
              />
            </div>
            <div className="preference-item">
              <div className="preference-label">
                <span>I get a content pack: </span>
              </div>
              <ReactSwitch
                checked={pack}
                onChange={(checked) => handleToggle("pack", checked)}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="pack-switch"
              />
            </div>
          </div>
        </div>

        <TextBlock texts={infoTexts} />
      </div>
    </BasePage>
  );
};

export default DashboardSettings;
