import React from "react";

const BackArrowIcon = ({ color = "white" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 24"
    fill="none"
    width="60%"
    height="60%"
  >
    <path
      d="M11.5119 2.48816L2 12L11.5119 21.5119"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6395 12.0002H2.26645"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackArrowIcon;
