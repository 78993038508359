import React from "react";

const ImageFadeText = ({ imageUrl, height, text, gradient = "1" }) => {
  const divStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, ${gradient}) 70%), url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: height,
    width: "auto",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "relative",
  };

  const textStyle = {
    fontStyle: "italic",
    marginBottom: ".25em",
  };

  const paragraphs = text.trim().split("\n");

  return (
    <div style={divStyle}>
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="undertitle" style={textStyle}>
          {paragraph.trim()}
        </p>
      ))}
    </div>
  );
};

export default ImageFadeText;
