import { React, createContext, useContext, useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import mixpanel from "./mixpanel";

const UserContext = createContext(null);

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [entitlement, setEntitlement] = useState("INACTIVE");

  const fetchPatronStatus = async () => {
    try {
      const { data, error } = await supabase
        .from("patron")
        .select("entitlement");

      if (error) throw error;

      if (data && data[0]) {
        setEntitlement(data[0].entitlement);
      } else {
        setEntitlement("INACTIVE");
      }
    } catch (error) {
      console.error("Error fetching patron status:", error);
    }
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session && session.user) {
        fetchPatronStatus(session.user.id);
        // Set Mixpanel user properties
        mixpanel.identify(session.user.email); // Identify the user with a unique ID
        mixpanel.people.set({
          $email: session.user.email, // Assuming Mixpanel identifies by email
          $userid: session.user.id,
          // You can add more properties here if needed
        });
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session && session.user) {
        fetchPatronStatus(session.user.id);
        mixpanel.identify(session.user.email);
        mixpanel.people.set({
          $email: session.user.email,
          $userid: session.user.id,
        });
      } else {
        // Handle the case when there is no user session
        mixpanel.reset(); // Resets the Mixpanel state, useful when a user logs out
      }
    });

    // Cleanup subscription on component unmount
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  return (
    <UserContext.Provider value={{ session, entitlement }}>
      {children}
    </UserContext.Provider>
  );
};
