import React from "react";
import signature from "../../components/emblems/theo-hartley.svg";

const PatreonThanks = () => {
  return (
    <div className="DashboardCTABackground">
      <div className="mid-width">
        <div className="element-margin-padding">
          <span className="mid-title secondary-font primary-color element-margin">
            Thank you for your support on Patreon!
          </span>
          <div />
          <span className="subtitle secondary-font accent-color element-margin">
            YOU make all this happen!
          </span>
          <div />
          <span className="subtitle secondary-font accent-color element-margin">
            YOU directly fund my writing!
          </span>
          <div />
          <div style={{ height: "55px" }} />
          <span className="title secondary-font primary-color element-margin">
            Eternally grateful,
          </span>
          <div style={{ height: "10px" }} />
          <img src={signature} alt="Theo Hartley" />
          <div style={{ height: "35px" }} />
        </div>
      </div>
    </div>
  );
};

export default PatreonThanks;
