import React from "react";

import mixpanel from "../../mixpanel";
import { Helmet } from "react-helmet";

import BasePage from "../../components/general/BasePage";
import TextBlock from "../../components/landing/TextBlock";
import TitleCard from "../../components/landing/TitleCard";
import GalleryOverlay from "../../components/landing/GalleryOverlay";
import ImageFadeText from "../../components/landing/ImageFadeText";
import CTAImageOverlay from "../../components/landing/CTAImageOverlay";
import CTABadge from "../../components/landing/CTABadge";
import InLineImage from "../../components/landing/InLineImage";
import SpacerLine from "../../components/landing/SpacerLine";
import Spacer from "../../components/landing/Spacer";

export const LauraLanding = () => {
  // Define test image URLs
  const imageUrlA = "https://www.otherworlderotic.com/assets/Laura-Hero.webp";
  const imageUrlB = "https://www.otherworlderotic.com/assets/Laura-Hero-B.webp";

  // Get the current hour using the JavaScript Date object
  const currentHour = new Date().getHours();
  // Determine if the current hour is even or odd
  const isEvenHour = currentHour % 2 === 0;

  // Based on the hour, set the appropriate image URL and test condition
  const imageUrl = isEvenHour ? imageUrlA : imageUrlB;
  const testIteration = "LA_ST_001";
  const testCondition = isEvenHour ? "A - water hero" : "B - red sports bra";

  const handleCTAClick = () => {
    mixpanel.track("CTA Click", {
      "test iteration": testIteration,
      "test condition": testCondition,
      "landing page": "LauraSt",
    });
  };

  const rootCTAURL = "/story/laura-the-bold/chapter/1";
  const spoilerCTAURL = "/laura-the-bold/erotica-preview";

  const fadeText = `
    "You know..." she begins, her voice a bit hesitant. "We've already come this far. What's one more layer?"
    You blink, unsure. "What do you mean?"
    "Well," Laura bites her lip, "We’re already mostly naked for this swim, we might as well save our underwear from getting wet. I mean I don't want to hike out in a soaked sports bra..."
    Your mind races, heart pounding at the suggestion. "So you mean -"
    "Yeah," she says, blushing. "Skinny dipping. I mean, if that’s ok with you, I don't mean to be a creep..."
  `;

  const featuresBlock = [
    {
      textAlignment: "left",
      text: "The full-length story features:",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "• an erotic story with fully nude pictures",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• slow-burn, girl-next-door romance",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• light conflict",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• (mostly) monogamy",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• open door sex scenes",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "• a happily ever after!",
      textStyle: "undertitle",
    },
  ];

  const infoText = [
    {
      textAlignment: "left",
      text: "Will you end up more than friends?",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "You and Laura just started your freshman year in college, and you were lucky enough to match with her in the campus 'buddy' program.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "How lucky will you really get?",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "Find out in the wholesome, novel-length illustrated erotic story about finding love, comfort, and quite a bit more...",
      textStyle: "undertitle",
    },
  ];

  const testimonials1 = [
    {
      textAlignment: "left",
      text: "\"I just found your site and I SPED through everything you've published so far. As a straight man, I didn't know this was something I was looking for but I absolutely love the mix of real storytelling and eroticism.\"",
      isItalic: true,
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "-u/laydownthebunt on Reddit",
      textStyle: "undertitle",
    },
  ];

  const testimonials2 = [
    {
      textAlignment: "left",
      text: '"Every new release just makes me enjoy your story telling more."',
      isItalic: true,
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "-Azurablades on Discord",
      textStyle: "undertitle",
    },
  ];

  const testimonials3 = `"Everything you write is realistic,  I love the way you are telling the story! It was so good that each time I try to resist the wish to look at the pictures of Laura first…"
  -u/NextBreakfast6032 on Reddit`;

  const helperText = [
    {
      textAlignment: "center",
      text: "I'm a one man team funded entirely by my generous patrons!",
      textStyle: "body",
    },
  ];

  const spoilerText = [
    {
      textAlignment: "center",
      text: "The build up is worth it, but if you want to skip to the action...",
      textStyle: "body",
    },
  ];

  const authorText = [
    {
      textAlignment: "left",
      text: "About the Author",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Theo Hartley writes the kind of erotic stories he wants to read: featuring realistic characters, happy relationships, slow-burn, blisteringly hot sex.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "Sex is so much better when there's realistic, rich characters and real emotional stakes.",
      isBold: true,
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Real romance, imaginary people.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "The fully explicit, photo-realistic illustrations that go with the story are created with digital art tools, photoshop, and open source AI tools. Laura and her friends aren’t based on any real people or their likeness.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "These characters are unique to these sexy illustrated erotic stories, you can only find them here on otherworlderotic.",
      textStyle: "undertitle",
    },
  ];

  const redheadText = [
    {
      textAlignment: "center",
      text: "Or perhaps a redhead...",
      isBold: true,
      textStyle: "subtitle",
    },
  ];

  const storyInfoTextOne = [
    {
      textAlignment: "left",
      text: "Fantasy grounded in reality",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "This story is different from what you typically get with erotica. Here, the characters feel like real people and their connections are as genuine as the ones you know.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "No overdone theatrics; just a compelling, down to earth story. Get wrapped up in a erotica where the sparks fly without needing to resort to tired tropes.",
      textStyle: "undertitle",
    },
  ];

  const storyInfoTextTwo = [
    {
      textAlignment: "left",
      text: "Positive and wholesome",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Explore an illustrated story where the heat is matched by heart. These stories get explicit, but they're consensual encounters that not only sizzle but also celebrate the joy of a positive, respectful connection. It's an erotic story that's as much about the chemistry as it is about the caring. Sex is better when there's a partnership built on trust and mutual pleasure.",
      textStyle: "undertitle",
    },
  ];

  const storyInfoTextThree = [
    {
      textAlignment: "left",
      text: "Novel-length erotic stories with a visual component",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "NSFW AI allowed the creation of this new form factor of erotica, similar to porn comics, but with a lot more substance. These illustrated stories feature the emotional intensity you can only get through a book, yet elevate the experience with enough pictures to keep things... stimulated.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "It's a seamless blend of the graphic allure found in adult comics and the deep, character-driven storytelling of a novel, offering a sophisticated twist on erotic enjoyment for those seeking substance with their sizzle.",
      textStyle: "undertitle",
    },
  ];

  const storyInfoTextFour = [
    {
      textAlignment: "left",
      text: "A note from the author",
      textStyle: "caption",
    },
    {
      textAlignment: "left",
      text: "Laura the Bold has reached its heartfelt conclusion; you can read the entire story now. It's a full story rich with vivid illustrations and genuine connections, and I hope it resonates with you as much as it did with me while I was writing it!",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "If you're looking for more, I'm already deep into creating new illustrated erotica for otherworlderotic, with fresh chapters coming out every week.",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "And if you're wondering about Laura, let's just say her story might have a part 2 coming soon. Keep an eye out!",
      textStyle: "undertitle",
    },
    {
      textAlignment: "left",
      text: "I hope you enjoy Laura the Bold!",
      textStyle: "undertitle",
    },
  ];

  const galleryImages = [
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Small1.webp",
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Small2.webp",
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Small3.webp",
    "https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Small4.webp",
  ];

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href={"https://www.otherworlderotic.com/assets/Laura-Hero.webp"}
          as="image"
        />
        <link
          rel="preload"
          href={"https://www.otherworlderotic.com/assets/Laura-Hero-B.webp"}
          as="image"
        />
        {/* General tags */}
        <title>
          Laura the Bold: an illustrated erotic story from otherworlderotic
        </title>
        <meta
          name="description"
          content="A lucky match just might lead to a new college girlfriend... Read Laura the Bold, an erotic illustrated story now!"
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="canonical"
          href="https://www.otherworlderotic.com/story/laura-the-bold/the-story"
        />
        <meta name="author" content="Theo Hartley" />
        <meta name="robots" content="index,follow" />

        {/* OpenGraph tags */}
        <meta property="og:site_name" content="Otherworlderotic" />
        <meta
          property="og:title"
          content="Laura the Bold: an illustrated erotic story from otherworlderotic"
        />
        <meta property="og:type" content={"book"} />
        <meta
          property="og:image"
          content={"https://www.otherworlderotic.com/assets/Laura-Hero.webp"}
        />
        <meta
          property="og:description"
          content={
            "A lucky match just might lead to a new college girlfriend... Read Laura the Bold, an erotic illustrated story now!"
          }
        />
        <meta
          property="og:url"
          content={
            "https://www.otherworlderotic.com/story/laura-the-bold/the-story"
          }
        />

        {/* Schema.org tags for Google */}
        <meta
          itemProp="name"
          content="Laura the Bold: an illustrated erotic story from otherworlderotic"
        />
        <meta
          itemProp="description"
          content={
            "A lucky match just might lead to a new college girlfriend... Read Laura the Bold, an erotic illustrated story now!"
          }
        />
        <meta
          itemProp="image"
          content={"https://www.otherworlderotic.com/assets/Laura-Hero.webp"}
        />

        {/* Schema.org structured data for Book */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Book",
            name: "Laura the Bold",
            url: "https://www.otherworlderotic.com/story/laura-the-bold/the-story",
            author: {
              "@type": "Person",
              name: "Theo Hartley",
            },
            image: "https://www.otherworlderotic.com/assets/Laura-Hero.webp",
            description:
              "A lucky match just might lead to a new college girlfriend... Read Laura the Bold, an erotic illustrated story now!",
            publisher: {
              "@type": "Organization",
              name: "Hartley Publications, LLC",
            },
          })}
        </script>
      </Helmet>
      <BasePage>
        <div className="page mid-width">
          <TitleCard
            imageUrl={imageUrl}
            height="850px"
            titleText="Laura the Bold"
            subtitleText="an illustrated, wholesome, and very erotic story."
            chapterCount="30"
            imageCount="510"
            readsCount="112k"
            likesCount="2.6k"
            altText="The cover for the erotic story Laura the Bold, by Theo Hartley"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <GalleryOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Hero%202.webp"
            height="950px"
            titleText="Good morning."
            subtitleText="an erotic story about the college girlfriend you never had."
            images={galleryImages}
            altText="Laura, your college girlfriend experience story, in cozy clothes in the morning."
          />

          <Spacer height="10px" />
          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <Spacer />

          <ImageFadeText
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Story%20Excerpt.webp"
            height="900px"
            text={fadeText}
          />

          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <Spacer />

          <TextBlock container={true} texts={featuresBlock} />
          <Spacer />

          <InLineImage
            linkTo={rootCTAURL}
            imageURL="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Cafe%20Cute.webp"
            altText="Laura wearing an orange sweater in a Paris cafe."
          />

          <TextBlock container={false} texts={testimonials1} />

          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <Spacer height="5px" />

          <TextBlock container={false} texts={testimonials2} />

          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Censored.webp"
            height="750px"
            CTAText="Meet Laura"
            CTAColor="#860000"
            CTAFontColor="white"
            altText="A censored image of Laura, naked, looking at the camera with a romantic look in her eyes."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <TextBlock container={false} texts={helperText} signature={true} />

          <ImageFadeText
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20in%20bed.webp"
            height="600px"
            text={testimonials3}
            gradient=".6"
          />

          <Spacer />

          <TextBlock container={true} texts={infoText} />

          {false && <SpacerLine />}
          {false && <TextBlock container={false} texts={spoilerText} />}
          <Spacer />
          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <Spacer />
          {false && <SpacerLine />}
          {false && (
            <CTABadge
              text="A sexy spoiler"
              badgeColor="#860000"
              textColor="white"
              paddingRight="10px"
              linkTo={spoilerCTAURL}
              onClick={handleCTAClick}
            />
          )}
          {false && <SpacerLine />}

          <TextBlock container={true} texts={authorText} signature={true} />
          <Spacer />
          <CTABadge
            text="Read Now"
            badgeColor="#4A39B6"
            textColor="white"
            paddingRight="10px"
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
          <SpacerLine />

          <TextBlock container={false} texts={redheadText} />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Jo%20Hero.webp"
            height="850px"
            CTAText="Meet Josephine"
            CTAColor="#860000"
            CTAFontColor="white"
            altText="Jo in a low cut dress and collar, seductively looking at the camera."
            linkTo="/story/josephine-on-fire/the-story"
            onClick={handleCTAClick}
          />
          <SpacerLine />

          <TextBlock container={true} texts={storyInfoTextOne} />
          <Spacer />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Cute%20Selfie.webp"
            height="750px"
            CTAText="Read Now"
            CTAColor="#4A39B6"
            CTAFontColor="white"
            altText="Laura in a cozy sweater, selfie shot."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <TextBlock container={true} texts={storyInfoTextTwo} />
          <Spacer />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20College%20Party.webp"
            height="750px"
            CTAText="Read Now"
            CTAColor="#4A39B6"
            CTAFontColor="white"
            altText="Laura at a college party in a skimpy tiger costume, with a big smile on her face."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <TextBlock container={true} texts={storyInfoTextThree} />
          <Spacer />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20Cafe%20Shorts.webp"
            height="750px"
            CTAText="Read Now"
            CTAColor="#4A39B6"
            CTAFontColor="white"
            altText="Laura in short shorts at a cafe outside."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />

          <TextBlock
            container={true}
            texts={storyInfoTextFour}
            signature={true}
          />
          <Spacer />
          <CTAImageOverlay
            imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Laura%20Story%201/Laura%20in%20Bra.webp"
            height="750px"
            CTAText="Read Now"
            CTAColor="#4A39B6"
            CTAFontColor="white"
            altText="Laura wearing a cute white bra."
            linkTo={rootCTAURL}
            onClick={handleCTAClick}
          />
        </div>
      </BasePage>
    </>
  );
};

export default LauraLanding;
