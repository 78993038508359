import React from "react";
import { useParams } from "react-router-dom";
import { useStories } from "../../StoryContext";

import BasePage from "../../components/general/BasePage";
import ChapterListItem from "./ChapterListItem";
import LoadingIndicator from "../../components/general/LoadingIndicator";

export const Story = () => {
  const { storyId } = useParams();
  const { stories } = useStories();

  if (stories.length === 0) {
    return <LoadingIndicator />;
  }

  const story = stories.find((story) => story.id === storyId);

  return (
    <BasePage>
      <div className="section column">
        {story.chapter.map((chapter, index) => (
          <ChapterListItem key={chapter.id} chapter={chapter} index={index} />
        ))}
      </div>
    </BasePage>
  );
};

export default Story;
