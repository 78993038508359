import React from "react";

import { Link } from "react-router-dom";
import ImageWithSkeleton from "../../components/general/ImageWithSkeleton";
import ChapterIcon from "../../components/emblems/ChapterIcon";
import NavList from "./NavList";

const TopNav = ({ story, chapter }) => {
  return (
    <div className="section two-column">
      <div className="container stretch tight-width">
        <div>
          <div className="chapter-link-container">
            <Link to={`/story/${story.id}`}>
              <div className="chapter-link">
                <ChapterIcon width="47" height="57" />
              </div>
            </Link>
            <p className="title secondary-font primary-color element-margin">
              Chapter {chapter.chapter_num}
            </p>
          </div>
          <p className="title secondary-font accent-color element-margin center-font">
            {chapter.title}
          </p>
        </div>

        <NavList story={story} chapter={chapter} />
      </div>

      <div className="container">
        <ImageWithSkeleton
          src={chapter.thumbnail_image_url}
          alt={chapter.title}
          height={512}
          width={512}
          disableFavorites={true}
        />
      </div>
    </div>
  );
};

export default TopNav;
