import React, { createContext, useState, useEffect, useContext } from "react";
import { supabase } from "./supabaseClient";

const StoryContext = createContext();

const StoryProvider = ({ children }) => {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    const fetchStoriesAndChapters = async () => {
      const { data, error } = await supabase
        .from("story")
        .select(
          `
        id,
        title,
        author,
        description,
        num_chapters,
        tagline,
        chapter (
            id,
            story_id,
            chapter_num,
            thumbnail_image_url,
            title,
            is_most_recent,
            visibility,
            release_datetime
            )
      `
        )
        .order("id", { ascending: false });

      if (error) {
        console.error("Failed to fetch stories:", error);
      } else {
        const sortedData = data.map((story) => ({
          ...story,
          chapter: [...story.chapter].sort(
            (a, b) => a.chapter_num - b.chapter_num
          ),
        }));

        setStories(sortedData);
      }
    };

    fetchStoriesAndChapters();
  }, []);

  return (
    <StoryContext.Provider value={{ stories }}>
      {children}
    </StoryContext.Provider>
  );
};

const useStories = () => {
  return useContext(StoryContext);
};

export { StoryProvider, useStories };
