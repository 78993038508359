import React from "react";
import { Link } from "react-router-dom";

const CTABadge = ({
  text,
  badgeColor,
  textColor,
  linkTo = "/",
  paddingRight = "3px",
  onClick,
}) => {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  const divStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "35px",
    backgroundColor: badgeColor,
    borderRadius: "50px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px",
    paddingRight: paddingRight,
  };

  const textStyle = {
    fontSize: "18px",
    color: textColor,
    fontWeight: "600",
    letterSpacing: "0.5px",
    marginRight: "20px",
  };

  const iconStyle = {
    height: "35px",
    width: "35px",
    lineHeight: "35px",
    textAlign: "center",
    backgroundColor: textColor,
    color: badgeColor,
    fontFamily: "Arial",
    borderRadius: "50%",
    fontSize: "30px",
  };

  const linkStyle = {
    textDecoration: "none",
  };

  return (
    <Link style={linkStyle} to={linkTo} onClick={handleClick}>
      <div className="ctaBadge" style={divStyle}>
        <span style={textStyle}>{text}</span>
        <span style={iconStyle}>➜</span>
      </div>
    </Link>
  );
};

export default CTABadge;
