import React from "react";

import BasePage from "../../components/general/BasePage";
import policy from "../../components/legal/privacy.js";

export const Privacy = () => {
  const parsedPolicy = policy.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
  return (
    <BasePage>
      <div className="section">
        <div className="container element-margin">
          <div className="">
            <h1 className="title secondary-font">Privacy Policy</h1>
            <p className="body">{parsedPolicy}</p>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default Privacy;
