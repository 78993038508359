import React from "react";

import BasePage from "../../components/general/BasePage";

const NotFound = () => {
  return (
    <BasePage>
      <h1>404 - Page Not Found</h1>
    </BasePage>
  );
};

export default NotFound;
