import React from "react";

import mixpanel from "../mixpanel";
import BasePage from "../components/general/BasePage";
import TextBlock from "../components/landing/TextBlock";
import Spacer from "../components/landing/Spacer";
import OverlayCard from "../components/landing/OverlayCard";
import CTABadge from "../components/landing/CTABadge";

export const CreditCard = () => {
  const handleCTAClick = () => {
    mixpanel.track("Patreon Click on CC", {
      "test iteration": "CC-PAT-INITIAL",
      "test condition": "CC-PAT-INITIAL",
    });
  };
  const featuresBlock = [
    {
      textAlignment: "center",
      text: "In the meantime, Patreon is the best option.",
      textStyle: "subtitle",
    },
    {
      textAlignment: "center",
      text: "Sorry for the inconvenience!",
      textStyle: "caption",
    },
    {
      textAlignment: "center",
      text: "You can reach me on Patreon messenger or Discord (otherworlderotic) with any comments or concerns!",
      textStyle: "undertitle",
    },
    {
      textAlignment: "center",
      text: "Thank you!",
      textStyle: "subtitle",
    },
  ];

  return (
    <BasePage>
      <div className="page mid-width">
        <OverlayCard
          imageUrl="https://fprltaqzwaaoemxmcywe.supabase.co/storage/v1/object/public/landing/Jo%20Story%201/Amy%20elegant%20dress.webp"
          height="750px"
          titleText="Work in Progress!"
          subtitleText="Sorry! I'm waiting on approval from a reputable payment processor!"
          altText="Thank you! From Theo Hartley"
          linkTo={"/patreon"}
          CTAtext="Support on Patreon"
          onClick={handleCTAClick}
        />
        <Spacer />
        <TextBlock container={false} texts={featuresBlock} signature={true} />
        <Spacer />
        <CTABadge
          text="Unlock Everything"
          badgeColor="#4A39B6"
          textColor="white"
          paddingRight="10px"
          linkTo={"/patreon"}
          onClick={handleCTAClick}
        />
        <Spacer />
      </div>
    </BasePage>
  );
};

export default CreditCard;
