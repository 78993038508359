import React from "react";

import CTABadge from "./CTABadge";

const OverlayCard = ({
  imageUrl,
  height,
  titleText,
  subtitleText,
  altText,
  linkTo,
  onClick,
  CTAtext = "Read Now",
}) => {
  const divStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 1) 100%), url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: height,
    width: "auto",
    borderRadius: "10px",
    padding: "10px",
    display: "grid",
    gridTemplateRows: "47% auto auto auto auto auto auto",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };

  return (
    <div style={divStyle} role="img" aria-label={altText}>
      <div className="title" style={{ gridRow: 2, textAlign: "center" }}>
        {titleText}
      </div>
      <div style={{ height: "20px" }}></div> {/* Spacer */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="undertitle"
          style={{ gridRow: 4, textAlign: "center", width: "80%" }}
        >
          {subtitleText}
        </div>
      </div>
      <div style={{ height: "10px" }}></div> {/* Spacer */}
      <div style={{ display: "flex", justifyContent: "center" }}></div>
      <div
        style={{
          display: "flex",
          gap: "30px",
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        <CTABadge
          text={CTAtext}
          badgeColor="#4A39B6"
          textColor="white"
          paddingRight="8px"
          linkTo={linkTo}
          onclick={onClick}
        />
      </div>
    </div>
  );
};

export default OverlayCard;
