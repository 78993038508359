import React from "react";

import BasePage from "../../components/general/BasePage";

export const About = () => {
  const aboutArtistContent =
    "Theo Hartley strives to create stories focused on positive, consensual, and life-affirming eroticism. He has a preference for slow-burning narratives that allow for the development of authentic emotional connections between characters.";

  const peopleNotRealContent =
    "All images on otherworlderotic are completely and entirely synthetic. That includes all characters, character features, and training of models. No likenesses of real people factored into the creation of character designs either. All resemblance to real humans, living or dead, is purely and utterly coincidental. The artist categorically and unequivocally supports right to privacy and denounces all non-consensual synthetic images.";

  const imagesLotOfWorkContent =
    "Nothing you see here was a single button click of a prompt. Every single image has had multiple layers of manipulation, photoshop, image editing, manual digital manipulation, and even occasionally the author posing as reference.";

  const reachOutContent =
    "If you have any concerns, questions, or support, I want to hear from you. Reach out to me on discord, reddit, patreon, or at otherworlderotic (at) gmail.com";

  return (
    <BasePage>
      <div className="section">
        <div className="container element-margin">
          <div className="">
            <h1 className="title secondary-font">about the artist</h1>
            <p className="body">{aboutArtistContent}</p>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container element-margin">
          <div className="">
            <h1 className="title secondary-font ">things to consider</h1>
            <h3 className="subtitle secondary-font accent-color">
              these people are not real
            </h3>
            <p className="body">{peopleNotRealContent}</p>
            <h3 className="subtitle secondary-font accent-color">
              these images were a lot of work
            </h3>
            <p className="body">{imagesLotOfWorkContent}</p>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container element-margin">
          <h1 className="title secondary-font">contact and support</h1>
          <div className="">
            <h3 className="subtitle secondary-font accent-color">
              you can reach out to me
            </h3>
            <p className="body">{reachOutContent}</p>

            <h3 className="subtitle secondary-font accent-color">support</h3>
            <p className="body">
              {
                "For general support, reach out to support@otherworlderotic.com or on discord!"
              }
            </p>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default About;
