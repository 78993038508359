import React from "react";

import InfoBadge from "./InfoBadge";
import CTABadge from "./CTABadge";

const TitleCard = ({
  imageUrl,
  height,
  titleText,
  subtitleText,
  chapterCount,
  imageCount,
  readsCount,
  likesCount,
  altText,
  linkTo,
  onClick,
  CTAtext = "Read Now",
  secondCTA = false,
  secondCTALinkTo = "/",
}) => {
  const divStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 1) 100%), url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: height,
    width: "auto",
    borderRadius: "10px",
    padding: "10px",
    display: "grid",
    gridTemplateRows: "47% auto auto auto auto auto auto",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };

  return (
    <div style={divStyle} role="img" aria-label={altText}>
      <div
        className="title tight-width"
        style={{ gridRow: 2, textAlign: "center" }}
      >
        {titleText}
      </div>
      <div style={{ height: "20px" }}></div> {/* Spacer */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="undertitle"
          style={{ gridRow: 4, textAlign: "center", width: "80%" }}
        >
          {subtitleText}
        </div>
      </div>
      <div style={{ height: "10px" }}></div> {/* Spacer */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <InfoBadge
          numberText="18+"
          text="NSFW"
          badgeColor="white"
          numberColor="black"
          textColor="#D90000"
          linkTo={linkTo}
        />
      </div>
      <div style={{ display: "flex", gap: "60px", justifyContent: "center" }}>
        <InfoBadge
          numberText={chapterCount}
          text={chapterCount === "1" ? "chapter" : "chapters"}
          badgeColor="black"
          numberColor="white"
          textColor="white"
          linkTo={linkTo}
        />
        <InfoBadge
          numberText={imageCount}
          text="images"
          badgeColor="black"
          numberColor="white"
          textColor="white"
          linkTo={linkTo}
        />
      </div>
      <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
        <div>👀 {readsCount} reads</div>
        <div>💜 {likesCount} likes</div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "30px",
          justifyContent: secondCTA ? "center" : "right",
          alignItems: "center",
        }}
      >
        {secondCTA && (
          <CTABadge
            text="About"
            badgeColor="#bdc4cf"
            textColor="black"
            paddingRight="8px"
            linkTo={secondCTALinkTo}
            onclick={onClick}
          />
        )}
        <CTABadge
          text={CTAtext}
          badgeColor="#4A39B6"
          textColor="white"
          paddingRight="8px"
          linkTo={linkTo}
          onclick={onClick}
        />
      </div>
    </div>
  );
};

export default TitleCard;
